import { useViewerStore } from "../store/store";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter.js";
import * as THREE from "three";

export const exportToGlb = async () => {
  const state = useViewerStore.getState();
  const {native} = state;

  const gltfExporter = new GLTFExporter();
  const scene = new THREE.Scene();

  Object.values(native).forEach((group) => {
    scene.add(group.clone());
  })

  const glb = await gltfExporter.parseAsync(scene, {
    binary : true
  }) as ArrayBuffer;

  return glb;
}
