import { ReactNode } from "react";
import styled from "styled-components";
import colors from "../../../contants/colors";

interface BooleanInputProps {
  label ?: string;
  className ?: string;
  value ?: boolean;
  onChange ?: (value : boolean) => void;
  isError ?: boolean;
  inline ?: boolean;
}

const BooleanInput : React.FC<BooleanInputProps> = (p) => {
  return (
    <Container className={p.inline ? 'inline' : ''}>
      <Input type="checkbox" onChange={e => p.onChange?.(e.target.checked)} className={(p.className || '') + (p.isError ? ' error' : '')} checked={!!p.value}/>
      {p.label && <Label>{p.label}</Label>}
    </Container>
  )
}

export default BooleanInput;

const Container = styled.div`
  margin-top : 1rem;

  &.inline {
    margin-top : 0;
    margin-right : 0.5rem;
  }
`

const Input = styled.input`
  background-color: ${colors.lightBackground};
  color : ${colors.darkText};
  border : 0.3rem solid transparent;
  border-radius: 0.5rem;
  outline: none;

  &.error {
    border-color : ${colors.error};
    box-shadow: inset 0px 0px 10px 0px rgba(255,0,0,0.41);
  }
`

const Label = styled.span`
  color : ${colors.text};
  font-size: 1rem;
  margin-bottom : 0.4rem
`
