import styled from "styled-components";
import colors from "../../../contants/colors";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useModelStore } from "../../../store/store";

const CameraPanel : React.FC<{}> = (p) => {
  const {selectItem} = useModelActions('editor');
  const itemSelection = useModelStore(state => state.itemSelection);

  const handleSelect = (item : string) => {
    selectItem({type : 'camera', item})
  }

  const isSelected = (item : string) => {
    return !!itemSelection && itemSelection.type === 'camera' && itemSelection.item === item
  }


  return (
    <Container>
      <Item onClick={() => handleSelect('position')} className={isSelected('position') ? 'selected' : ''}>Default position</Item>
      <Item onClick={() => handleSelect('rotation')} className={isSelected('rotation') ? 'selected' : ''}>Default rotation</Item>
      <Item onClick={() => handleSelect('fov')} className={isSelected('fov') ? 'selected' : ''}>Default FOV</Item>
      <Item onClick={() => handleSelect('contraint')} className={isSelected('contraint') ? 'selected' : ''}>Contraint</Item>
    </Container>
  )
}

export default CameraPanel;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding : 0.3rem;
  color : ${colors.text};
  border-bottom : 0.1rem solid ${colors.backgroundLight};
  cursor : pointer;

  &.selected {
    background-color: ${colors.backgroundSelect};
  }
`
