import { assert } from "../assert-type";

export type UglaFile = {
  assets : Asset[];
  scene : SceneObject[];
  camera ?: Camera;
  defaultControls ?: Control;
  defaultInteractionsGroups ?: string[];
  interactions : Record<string, Interaction[]>;
  mods ?: Record<string, Modification[]>;
  animations ?: Record<string, Animation>;
  lights ?: Light[];
}

export type Asset = {
  type : AssetType;
  url  : string;
  md5  ?: string;
  tags ?: Tag[];
}

export const isAsset = (a : any, log : string[]) : a is Asset => {
  return !!a &&
    assert(typeof a.type === 'string', 'type', log) &&
    assert(['3D', '2D', 'Skybox', 'Video'].includes(a.type), 'type', log) &&
    assert(typeof a.url === 'string', 'url', log) &&
    assert((a.md5 === undefined || typeof a.md5 === 'string'), 'md5', log) &&
    assert(a.tags === undefined || (Array.isArray(a.tags) && a.tags.every((t : any) => isTag(t, log))), 'tags', log);
}

export type AssetType = '3D' | '2D' | 'Skybox' | 'Video';

export type SceneObject<A = AssetRef> = {
  id : string;
  asset : A;
  mods ?: Modification<A>[];
  tags ?: Tag[];
}

export const isSceneObject = (a : any, log : string[] = []) : a is SceneObject => {
  return !!a &&
    assert(typeof a.id === 'string', 'id', log) &&
    assert(typeof a.asset === 'number', 'asset', log) &&
    assert((a.mods === undefined || (Array.isArray(a.mods) && a.mods.every((m : any) => isModification(m, log)))), 'mods', log) &&
    assert((a.tags === undefined || (Array.isArray(a.tags) && a.tags.every((t : any) => isTag(t, log)))), 'tags', log);
}

export const isAssetSceneObject = (a : any, log : string[] = []) : a is SceneObject<Asset> => {
  return !!a &&
    assert(typeof a.id === 'string', 'id', log) &&
    assert(isAsset(a.asset, log), 'asset', log) &&
    assert((a.mods === undefined || (Array.isArray(a.mods) && a.mods.every((m : any) => isModification(m, log)))), 'mods', log) &&
    assert(a.tags === undefined || (Array.isArray(a.tags) && a.tags.every((t : any) => isTag(t, log))), 'tags', log);
}

export type AssetRef = number; // index dans l'array des assets
export const isAssetRef = (a : any) : a is AssetRef => typeof a === 'number';

export type Modification3D<A = AssetRef> = {
  type : '3D';
  path : ObjectPath;
  position     ?: Vec3;
  rotation     ?: Vec3;
  scale        ?: Vec3;
  materialName ?: string;
  material     ?: Material<A>;
  relative     ?: boolean;
}

export type Material<A = AssetRef> = {
  baseColor ?: string;
  diffuse   ?: A;
  roughness ?: A;
  normalMap ?: A;
  tiling    ?: Vec2;
}

export type Modification2D = {
  type : '2D';
  path : ObjectPath;
  position  ?: Vec2;
  rotation  ?: number;
  scale     ?: Vec2;
  zIndex    ?: number;
  relative  ?: boolean;
}

export type ModificationLight = {
  type : 'light';
  path : ObjectPath;
  relative  ?: boolean;
} & Omit<Light, 'id' |'type'>;

export type ModificationType = Modification['type'];
export type Modification<A = AssetRef> = Modification3D<A> | Modification2D | ModificationLight;

export const isModification = (m : any, log : string[] = []) : m is Modification=> {
  return assert(!!m, 'empty modification', log) &&
    (
      (m.type === '2D' &&
        assert(
          assert(isObjectPath(m.path, log), 'path', log) &&
          assert(m.position === undefined || isVec2(m.position), 'position', log) &&
          assert(m.rotation === undefined || typeof m.rotation === 'number', 'rotation', log) &&
          assert(m.scale === undefined || isVec2(m.scale), 'scale', log) &&
          assert(m.zIndex === undefined || typeof m.zIndex === 'number', 'zIndex', log)
        , "2D", log)
      ) ||
      (m.type === '3D' &&
        assert(
          assert(isObjectPath(m.path, log), 'path', log) &&
          assert((m.position === undefined || isVec3(m.position)), 'position', log) &&
          assert((m.rotation === undefined || isVec3(m.rotation)), 'rotation', log) &&
          assert((m.scale === undefined || isVec3(m.scale)), 'scale', log) &&
          assert((m.materialName === undefined || typeof m.materialName === 'string'), 'materialName', log)
          // TODO: add material
        , "3D", log)
      ) ||
      (m.type === 'light' &&
        assert(
          assert(isObjectPath(m.path, log), 'path', log) &&
          assert(isLight({...m, type : 'spot', id:''}, log), 'light', log)
        , "light", log)
      )
    )
}

export type Camera = {
    defaultPosition ?: Vec3;
    defaultRotation ?: Vec4;
    defaultFov      ?: number;
    constraint      ?: CameraConstraint;
}

export const isCamera = (c : any, log : string[] = []) : c is Camera => {
  return !!c &&
    assert(!c.defaultPosition || isVec3(c.defaultPosition), 'defaultPosition', log) &&
    assert(!c.defaultRotation || isVec4(c.defaultRotation), 'defaultRotation', log) &&
    assert(!c.defaultFov || typeof c.defaultFov === 'number', 'defaultFov', log);
}

type CameraConstraint = {
  type : 'surface';
  glbObjects ?: boolean;
  perimetre : Vec3[] | ObjectPath[];
  tags ?: Tag[];
} | {
  type : 'viewpoint';
  glbObjects ?: boolean;
  viewpoints : Vec3[] | ObjectPath[];
  tags ?: Tag[];
} | {
  type : 'rotation';
  pivot : ObjectPath;
  rotationRange ?: [number, number];
  radiusRange ?: [number, number];
  tags ?: Tag[];
}


export type Control = {
  type : 'orbit';
} | {
  type : 'walk';
  height ?: number;
  defaultPosition ?: Vec2;
} | {
  type : 'none';
}

export type ControlType = Control['type'];

export const isControl = (c : any, log : string[] = []) : c is Control => {
  return assert(!!c && (
    c.type === 'orbit' || c.type === 'none' ||
    (
      c.type === 'walk' &&
      (typeof c.height === 'number' || c.height === undefined) &&
      (c.defaultPosition === undefined || isVec2(c.defaultPosition))
    )
  ), 'control', log);
}

export type TouchInteraction<A = AssetRef> = {
  id : string;
  type : 'touch';
  path : ObjectPath;
  actions : Action<A>[];
  marker ?: A;
  markerScale ?: Vec3;
  tags ?: Tag[];
};
export type InterfaceInteraction<A = AssetRef> = {
  id : string;
  type : 'interface';
  position : Vec2;
  actions : Action<A>[];
  marker ?: A;
  tags ?: Tag[];
};
export type HiddenInteraction<A = AssetRef> = {
  id : string;
  type : 'hidden';
  actions : Action<A>[];
  tags ?: Tag[];
}
export type Interaction<A = AssetRef> = TouchInteraction<A> | InterfaceInteraction<A> | HiddenInteraction<A>

export type InteractionType = Interaction['type'];

export const isInteraction = (i : any, log : string[] = []) : i is Interaction => {
  return !!i &&
    assert(typeof i.id === 'string', 'id', log) &&
    assert(Array.isArray(i.actions) && i.actions.every((a : any) => isAction(a, log)), 'actions', log) &&
    assert(!i.tags || (Array.isArray(i.tags) && i.tags.every((t:any) => isTag(t, log))), 'tags', log) &&
  (
    (
      i.type === 'touch' &&
      assert(
        isObjectPath(i.path, log) &&
        (!i.marker || isAssetRef(i.marker)) &&
        (!i.markerScale || isVec3(i.markerScale))
      , 'type touch', log)
    ) ||
    (  i.type === 'interface' &&
      assert(
        isVec2(i.position) &&
        (!i.marker || isAssetRef(i.marker))
      , 'type interface', log)
    ) ||
    (
      i.type === 'hidden'
    )
  )
}

export type Action<A = AssetRef> = {
  type : 'interaction-mode';
  name : string;
} | {
  type : 'add-interactions-group';
  name : string;
} | {
  type : 'remove-interactions-group';
  name : string;
  regex ?: boolean;
} | {
  type : 'show';
  path : ObjectPath;
} | {
  type : 'hide';
  path : ObjectPath;
} | {
  type : 'toggle';
  path : ObjectPath;
} | {
  type : 'add';
  object : SceneObject<A>;
} | {
  type : 'remove';
  objectId : string;
  regex ?: boolean;
} | {
  type : 'mod';
  id : string;
  mods : Modification<A>[];
} | {
  type : 'reset-mod';
  id : string;
  regex ?: boolean;
} | {
  type : 'fit-into-view';
  path : ObjectPath;
} | {
  type : 'reset-view';
} | {
  type : 'show-label';
  path : ObjectPath;
  asset : A;
} | {
  type : 'hide-label';
  path : ObjectPath;
} | {
  type : 'animate';
  path : ObjectPath;
  animation : string;
} | {
  type : 'focus';
  path : ObjectPath;
} | {
  type : 'blur';
}

export type ActionType = Action['type'];

export const isAction = (a : any, log : string[] = []) : a is Action => {
  return !!a && assert(
    (a.type === 'interaction-mode' && typeof a.name === 'string') ||
    (a.type === 'add-interactions-group' && typeof a.name === 'string') ||
    (a.type === 'remove-interactions-group' && typeof a.name === 'string' && (a.regex === undefined || typeof a.regex === 'boolean')) ||
    (a.type === 'interaction-mode' && typeof a.name === 'string') ||
    (a.type === 'show' && isObjectPath(a.path, log)) ||
    (a.type === 'hide' && isObjectPath(a.path, log)) ||
    (a.type === 'toggle' && isObjectPath(a.path, log)) ||
    (a.type === 'add' && isSceneObject(a.object, log)) ||
    (a.type === 'remove' && typeof a.objectId === 'string') ||
    (a.type === 'mod' && typeof a.id === 'string' && Array.isArray(a.mods) && a.mods.every((m : any) => isModification(m, log))) ||
    (a.type === 'reset-mod' && typeof a.id === 'string') ||
    (a.type === 'fit-into-view' && isObjectPath(a.paths, log)) ||
    (a.type === 'reset-view') ||
    (a.type === 'show-label' && isObjectPath(a.path, log) && isAsset(a.asset, log)) ||
    (a.type === 'hide-label' && isObjectPath(a.path, log)) ||
    (a.type === 'animate' && isObjectPath(a.path, log) && typeof a.animation === "string") ||
    (a.type === 'focus' && isObjectPath(a.path, log)) ||
    (a.type === 'blur')
  , 'action:' + a.type, log);
}

export type Vec2 = [number, number];
export const isVec2 = (v : any) : v is Vec3 => {
  return !!v &&
    Array.isArray(v) &&
    v.length === 2 &&
    v.every((v : any) => typeof v === 'number');
}

export type Vec3 = [number, number, number];
export const isVec3 = (v : any) : v is Vec3 => {
  return !!v &&
    Array.isArray(v) &&
    v.length === 3 &&
    v.every((v : any) => typeof v === 'number');
}

export type Vec4 = [number, number, number, number];
export const isVec4 = (v : any) : v is Vec4 => {
  return !!v &&
    Array.isArray(v) &&
    v.length === 4 &&
    v.every((v : any) => typeof v === 'number');
}

export type ObjectPath = {
  objectId : string;
  path : (string | number)[];
  regex ?: boolean;
};
export const isObjectPath = (o : any, log : string[] = []) : o is ObjectPath => {
  return assert(!!o, 'empty object path', log) &&
    assert(typeof o.objectId === 'string', 'objectId', log) &&
    assert(
      Array.isArray(o.path) &&
      o.path.every((p : any) => typeof p === 'number' || typeof p === 'string')
    , 'object-path', log);
}

export type Tag = {key : string, value : string};
export const isTag = (t : any, log : string[] = []) : t is Tag => {
  return !!t &&
    assert(typeof t.key === 'string' &&
    typeof t.value === 'string', 'key value', log);
}



export type Animation = {
  tracks : AnimationTrack[]
}

export const isAnimation = (a : any, log : string[] = []) : a is Animation => {
  return !!a &&
    assert(Array.isArray(a.tracks) && a.tracks.every((t : any) => isAnimationTrack(t)), 'tracks', log);
}

export type AnimationTrack = {
  objectPath : ObjectPath;
  property : string,
  times : number[],
  values : number[]
}
export const isAnimationTrack = (t : any, log : string[] = []) : t is AnimationTrack => {
  return !!t &&
    assert(isObjectPath(t.objectPath), 'objectPath', log) &&
    assert(typeof t.property === 'string', 'property', log) &&
    assert(Array.isArray(t.times) && t.times.every((_t : any) => typeof _t === 'number'), 'times', log) &&
    assert(Array.isArray(t.values) && t.values.every((_v : any) => typeof _v === 'number'), 'values', log)
}

export type LightType = 'ambient' | 'directional' | 'point' | 'spot';

export type Light = {
  id : string;
  type : LightType;
  color ?: string;
  intensity ?: number;
  distance ?: number;
  angle ?: number;
  penumbra ?: number;
  decay ?: number;
  position ?: Vec3;
  target ?: Vec3;
  castShadow ?: boolean;
}

export const isLight = (l : any, log : string[] = []) : l is Light => {
  return !!l && typeof l.id === 'string' &&
    assert(typeof l.type === 'string' && ['ambient', 'directional', 'point', 'spot'].includes(l.type), 'type', log) &&
    assert(!l.color || typeof l.color === 'string', 'color', log) &&
    assert(!l.intensity || typeof l.intensity === 'number', 'intensity', log) &&
    assert(!l.distance || typeof l.distance === 'number', 'distance', log) &&
    assert(!l.angle || typeof l.angle === 'number', 'angle', log) &&
    assert(!l.penumbra || typeof l.penumbra === 'number', 'penumbra', log) &&
    assert(!l.position || isVec3(l.position), 'position', log) &&
    assert(!l.target || isVec3(l.target), 'target', log) &&
    assert(!l.castShadow || typeof l.castShadow === 'boolean', 'castShadow', log);
}

