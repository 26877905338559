import { useEffect, useState } from "react";
import Modal from "../components/modal"
import TextButton from "../components/text-button";
import TextInput from "../components/text-input";
import useStorageProvider, { nameFromKey, StorageFile } from "../../../providers/storageprovider";
import styled from "styled-components";
import colors from "../../../contants/colors";

interface OpenCloudFileModalProps {
  onClose ?: () => void;
  onSubmit ?: (key : string) => void;
  selectName ?: boolean;
}

const OpenCloudFileModal : React.FC<OpenCloudFileModalProps> = (p) => {
  const [prefix, setPrefix] = useState<string>('');
  const [files, setFiles] = useState<StorageFile[]>([]);
  const [selected, setSelected] = useState<string>('');
  const [fileName, setFileName] = useState<string>('');

  const storageProvider = useStorageProvider();

  const handleOpen = () => {
    p.onClose?.();
    fileName && p.onSubmit?.((prefix + '/' + fileName).replace(/\/+/g, '/'));
  }

  const handleBack = () => {
    setPrefix(prefix => prefix.replace(/\/+$/g, '').split('/').filter(f => !!f).slice(0, -1).join('/'));
  }

  const handleSelect = (name : string) => {
    setSelected(name);
    setFileName(nameFromKey(name) || '');
  }

  useEffect(() => {
    storageProvider.listAll(prefix).then(files => setFiles(files))
  }, [prefix])

  return (
    <Modal
      title={p.selectName ? "Store file into cloud storage" : "Open file from cloud storage"}
      onClose={p.onClose}
      controls={
        <TextButton disabled={!fileName} onClick={handleOpen}>{p.selectName ? 'Save' : 'Open'}</TextButton>
      }
    >
      <TextButton onClick={() => setPrefix('')}>⇤</TextButton>
      <TextButton onClick={handleBack}>←</TextButton>
      <Scroller>
        {
          files.map(file => (
            file.directory ?
              <Directory key={file.key} onClick={() => setPrefix(file.key)}>▶︎ {nameFromKey(file.key)}</Directory> :
              <StandardFile key={file.key} onDoubleClick={() => {handleSelect(file.key);handleOpen();}} onClick={() => handleSelect(file.key)} className={file.key === selected ? 'selected' : ''}>{nameFromKey(file.key)}</StandardFile>
          ))
        }
      </Scroller>
      {
        p.selectName ?
          <TextInput value={fileName} onChange={setFileName}/> :
          null
      }
    </Modal>
  )
}

export default OpenCloudFileModal


const Scroller = styled.div`
  max-height : 70vh;
  overflow: auto;
`
const Directory = styled.div`
  color : ${colors.text};
  font-weight: 900;
  border-bottom : 1px solid #EEE;
  padding-top : 0.5rem;
  padding-bottom : 0.5rem;
  cursor : pointer;
`

const StandardFile = styled.div`
  color : ${colors.text};
  border-bottom : 1px solid #EEE;
  padding-top : 0.5rem;
  padding-bottom : 0.5rem;
  cursor : pointer;

  &.selected {
    background-color: ${colors.backgroundSelect};
  }
`