import colors from "../../contants/colors";
import IconProps from "./icon-interface";

const CloudDownloadIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
  <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.9999 80.0101C49.5879 79.9869 49.2007 79.8054 48.9193 79.5035C48.638 79.2017 48.4841 78.8027 48.4899 78.3901V48.1801C48.4841 47.7674 48.638 47.3685 48.9193 47.0666C49.2007 46.7647 49.5879 46.5833 49.9999 46.5601C50.2068 46.5547 50.4127 46.5909 50.6054 46.6664C50.798 46.7419 50.9737 46.8552 51.1219 46.9997C51.2701 47.1441 51.3878 47.3168 51.4682 47.5075C51.5486 47.6982 51.59 47.9031 51.5899 48.1101V78.5001C51.5847 78.7037 51.5395 78.9042 51.4567 79.0903C51.374 79.2764 51.2554 79.4443 51.1078 79.5845C50.9601 79.7248 50.7863 79.8345 50.5962 79.9075C50.4061 79.9806 50.2035 80.0154 49.9999 80.0101Z" fill={color}/>
    <path d="M49.9999 80.9H49.9399C49.2953 80.8715 48.6869 80.5939 48.2429 80.1257C47.799 79.6574 47.5541 79.0352 47.5599 78.39V48.18C47.5541 47.5347 47.799 46.9125 48.2429 46.4442C48.6869 45.976 49.2953 45.6984 49.9399 45.67C50.2657 45.6606 50.5901 45.7168 50.8937 45.8353C51.1973 45.9539 51.474 46.1323 51.7072 46.3599C51.9405 46.5876 52.1255 46.8598 52.2514 47.1605C52.3773 47.4611 52.4414 47.784 52.4399 48.11V78.5C52.4401 78.8254 52.3756 79.1477 52.2503 79.4481C52.125 79.7485 51.9413 80.0211 51.7099 80.25C51.2463 80.6811 50.6328 80.9143 49.9999 80.9ZM49.9999 79.12C50.0879 79.1213 50.1753 79.1053 50.2571 79.0728C50.3389 79.0403 50.4135 78.9921 50.4766 78.9308C50.5398 78.8695 50.5902 78.7964 50.6251 78.7156C50.66 78.6348 50.6786 78.5479 50.6799 78.46V48.11C50.6802 48.0207 50.6627 47.9322 50.6284 47.8498C50.594 47.7673 50.5436 47.6926 50.4799 47.63C50.4129 47.5769 50.3358 47.5379 50.2533 47.5156C50.1708 47.4932 50.0846 47.4879 49.9999 47.5C49.8205 47.5176 49.6545 47.6029 49.5357 47.7385C49.4168 47.874 49.354 48.0498 49.3599 48.23V78.39C49.354 78.5702 49.4168 78.7459 49.5357 78.8814C49.6545 79.017 49.8205 79.1023 49.9999 79.12Z" fill={color}/>
    <path d="M51.7299 82.0402C51.2633 82.484 50.6439 82.7316 49.9999 82.7316C49.3559 82.7316 48.7365 82.484 48.2699 82.0402L37.1499 71.5002C36.8502 71.2177 36.675 70.8278 36.6628 70.4161C36.6506 70.0045 36.8024 69.6049 37.0849 69.3052C37.3673 69.0055 37.7573 68.8303 38.1689 68.8181C38.5806 68.8059 38.9802 68.9577 39.2799 69.2402L48.9299 78.3502C49.2182 78.6253 49.6014 78.7787 49.9999 78.7787C50.3984 78.7787 50.7816 78.6253 51.0699 78.3502L60.7199 69.2402C61.0196 68.9577 61.4192 68.8059 61.8309 68.8181C62.2425 68.8303 62.6324 69.0055 62.9149 69.3052C63.1973 69.6049 63.3492 70.0045 63.337 70.4161C63.3248 70.8278 63.1496 71.2177 62.8499 71.5002L51.7299 82.0402Z" fill={color}/>
    <path d="M49.9998 83.62C49.1298 83.6199 48.2926 83.2872 47.6598 82.69L36.5398 72.19C36.0759 71.7473 35.8032 71.1409 35.7798 70.5C35.7663 69.8543 36.0072 69.2291 36.4507 68.7595C36.8941 68.2899 37.5044 68.0135 38.1498 67.99C38.7987 67.9737 39.4277 68.2146 39.8998 68.66L49.5398 77.77C49.6653 77.8853 49.8295 77.9492 49.9998 77.9492C50.1702 77.9492 50.3344 77.8853 50.4598 77.77L60.1098 68.66C60.4607 68.3304 60.9011 68.1119 61.3758 68.0321C61.8505 67.9522 62.3383 68.0146 62.7776 68.2112C63.217 68.4079 63.5883 68.7302 63.845 69.1375C64.1016 69.5447 64.232 70.0188 64.2198 70.5C64.213 70.8212 64.1423 71.1379 64.0118 71.4315C63.8813 71.7251 63.6937 71.9897 63.4598 72.21L52.3398 82.69C51.7071 83.2872 50.8699 83.6199 49.9998 83.62ZM38.2198 69.75C38.1331 69.7531 38.0479 69.7732 37.9689 69.8092C37.89 69.8453 37.8189 69.8965 37.7598 69.96C37.6991 70.0218 37.6518 70.0954 37.6209 70.1763C37.5899 70.2571 37.5759 70.3435 37.5798 70.43C37.581 70.5161 37.5993 70.6011 37.6337 70.6801C37.668 70.7591 37.7177 70.8304 37.7798 70.89L48.8798 81.4C49.1836 81.684 49.584 81.842 49.9998 81.842C50.4157 81.842 50.8161 81.684 51.1198 81.4L62.2398 70.9C62.367 70.7757 62.4421 70.6077 62.4498 70.43C62.4532 70.2559 62.3886 70.0874 62.2698 69.96C62.1455 69.8329 61.9775 69.7578 61.7998 69.75C61.6261 69.7487 61.4583 69.813 61.3298 69.93L51.6798 79.04C51.2253 79.4683 50.6243 79.7068 49.9998 79.7068C49.3753 79.7068 48.7744 79.4683 48.3198 79.04L38.6698 69.93C38.6104 69.8718 38.5401 69.8259 38.4628 69.795C38.3856 69.7641 38.303 69.7488 38.2198 69.75Z" fill={color}/>
    <path d="M25.9999 39.8104C25.4218 39.8092 24.8597 39.6204 24.398 39.2724C23.9364 38.9243 23.6002 38.4359 23.4399 37.8804C22.2637 33.7881 22.7613 29.3962 24.8233 25.6707C26.8852 21.9453 30.3427 19.1916 34.4349 18.0154C38.5272 16.8391 42.9192 17.3367 46.6446 19.3987C50.37 21.4607 53.1237 24.9181 54.2999 29.0104C54.4473 29.3573 54.5195 29.7315 54.5118 30.1083C54.5042 30.4851 54.4168 30.856 54.2555 31.1967C54.0942 31.5373 53.8627 31.8399 53.576 32.0846C53.2894 32.3294 52.9542 32.5106 52.5925 32.6165C52.2308 32.7224 51.8507 32.7505 51.4774 32.699C51.104 32.6475 50.7458 32.5175 50.4263 32.3176C50.1068 32.1177 49.8332 31.8524 49.6235 31.5392C49.4139 31.226 49.2729 30.872 49.2099 30.5004C48.8407 29.1251 48.2011 27.8371 47.3286 26.7118C46.4561 25.5864 45.3681 24.6461 44.1282 23.9458C42.8883 23.2456 41.5213 22.7994 40.107 22.6333C38.6927 22.4672 37.2595 22.5845 35.8911 22.9784C34.5227 23.3724 33.2465 24.035 32.137 24.9276C31.0274 25.8202 30.1069 26.9249 29.429 28.1772C28.7512 29.4295 28.3296 30.8043 28.189 32.2214C28.0484 33.6384 28.1915 35.0693 28.6099 36.4304C28.7223 36.8299 28.7397 37.2501 28.6607 37.6575C28.5816 38.0649 28.4084 38.4481 28.1547 38.7766C27.9011 39.105 27.5741 39.3696 27.2 39.5491C26.8258 39.7286 26.4149 39.8181 25.9999 39.8104Z" fill={color}/>
    <path d="M80.7699 48.5002C80.3192 48.4988 79.876 48.3854 79.4799 48.1702C78.8675 47.82 78.4185 47.2417 78.2312 46.5615C78.0438 45.8813 78.1332 45.1546 78.4799 44.5402C80.2675 41.3099 80.6986 37.5017 79.6784 33.9536C78.6582 30.4054 76.2703 27.4078 73.0399 25.6202C69.8096 23.8327 66.0015 23.4016 62.4533 24.4218C58.9051 25.442 55.9075 27.8299 54.1199 31.0602C53.7534 31.6261 53.1863 32.0324 52.5325 32.1974C51.8788 32.3625 51.1868 32.2741 50.5955 31.95C50.0043 31.6258 49.5575 31.09 49.3451 30.4501C49.1326 29.8102 49.17 29.1136 49.4499 28.5002C51.996 24.1937 56.1202 21.0489 60.947 19.7334C65.7738 18.4179 70.9233 19.0353 75.3024 21.4545C79.6815 23.8737 82.9453 27.9043 84.4012 32.6907C85.8571 37.4771 85.3903 42.6424 83.0999 47.0902C82.8747 47.5134 82.5392 47.8678 82.1291 48.116C81.7189 48.3642 81.2493 48.497 80.7699 48.5002Z" fill={color}/>
    <path d="M84.5499 73.7799H84.4699C83.7617 73.7799 83.0826 73.4986 82.5819 72.9979C82.0812 72.4972 81.7999 71.818 81.7999 71.1099C81.7999 70.4018 82.0812 69.7226 82.5819 69.2219C83.0826 68.7212 83.7617 68.4399 84.4699 68.4399H84.5399C86.0849 68.4454 87.6105 68.0946 88.9979 67.4148C90.3854 66.7349 91.5974 65.7443 92.5399 64.5199C93.4779 63.3316 94.127 61.9417 94.4359 60.4596C94.7448 58.9775 94.7051 57.444 94.3199 55.9799C94.3012 55.892 94.2778 55.8052 94.2499 55.7199C93.4873 53.0761 91.7072 50.8428 89.3 49.5099C86.8929 48.177 84.0553 47.8533 81.4099 48.6099C81.0644 48.7375 80.6962 48.792 80.3286 48.7699C79.961 48.7478 79.602 48.6497 79.2743 48.4816C78.9466 48.3136 78.6574 48.0793 78.425 47.7937C78.1925 47.508 78.0219 47.1772 77.9239 46.8222C77.826 46.4672 77.8028 46.0958 77.8559 45.7313C77.909 45.3669 78.0371 45.0175 78.2323 44.7052C78.4274 44.3929 78.6853 44.1245 78.9896 43.917C79.2938 43.7095 79.6379 43.5675 79.9999 43.4999C83.9978 42.363 88.2833 42.8557 91.9189 44.8703C95.5545 46.8849 98.2443 50.2573 99.3999 54.2499C99.3999 54.3799 99.4699 54.5099 99.5099 54.6399C100.1 56.8938 100.161 59.2538 99.6873 61.5351C99.2137 63.8164 98.2186 65.9572 96.7799 67.7899C95.3375 69.6597 93.4842 71.1725 91.3635 72.2112C89.2427 73.2499 86.9113 73.7867 84.5499 73.7799Z" fill={color}/>
    <path d="M17.83 73.9903C14.3215 73.9884 10.8932 72.941 7.98274 70.9817C5.07231 69.0224 2.81184 66.2402 1.48995 62.9903C0.0402602 59.3269 -0.366524 55.3337 0.314706 51.4533C0.995936 47.5729 2.73856 43.957 5.34935 41.0065C7.96014 38.0561 11.3371 35.8863 15.1057 34.7379C18.8744 33.5894 22.8874 33.5072 26.7 34.5003C27.3487 34.7092 27.8929 35.1584 28.2212 35.7556C28.5494 36.3529 28.6368 37.0531 28.4655 37.7128C28.2942 38.3724 27.8772 38.9416 27.2998 39.3037C26.7224 39.6658 26.0284 39.7933 25.36 39.6603C22.4991 38.9144 19.4875 38.9758 16.6594 39.8376C13.8313 40.6994 11.2973 42.3279 9.33858 44.5425C7.37986 46.7571 6.07304 49.471 5.56314 52.3832C5.05324 55.2954 5.3602 58.2919 6.44995 61.0403C7.37556 63.297 8.95226 65.2269 10.979 66.584C13.0058 67.9412 15.3908 68.6639 17.83 68.6603H17.88C18.5585 68.7019 19.1957 69.0007 19.6615 69.4959C20.1273 69.9912 20.3866 70.6454 20.3866 71.3253C20.3866 72.0051 20.1273 72.6594 19.6615 73.1546C19.1957 73.6498 18.5585 73.9487 17.88 73.9903H17.83Z" fill={color}/>
  </svg>
  )
}

export default CloudDownloadIcon;
