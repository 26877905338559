import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import Typography from "../components/typography";
import colors from "../../../contants/colors";
import { useModelActions } from "../../../store/hooks/use-actions";
import Vec1Input from "../components/vec1-input";

const EditorDefaultFov : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const model = useModelStore(state => state.model);
  const defaultFov = model.camera()?.defaultFov;
  const {setDefaultFov, clearDefaultFov} = useModelActions('camera');

  if(!itemSelection || itemSelection.type !== 'camera' || itemSelection.item !== 'fov') {return null;}

  return (
    <Container>
      <Typography variant="mediumTitle">Default FOV</Typography>
      <Vec1Input value={defaultFov} onChange={setDefaultFov} onDelete={clearDefaultFov} label="FOV"/>
    </Container>
  )
}

export default EditorDefaultFov;

const Container = styled.div`
  padding : 1rem;
  color : ${colors.text}
`
