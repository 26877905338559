import { useState } from "react";
import Modal from "../components/modal"
import TextButton from "../components/text-button";
import TextInput from "../components/text-input";

interface InteractionsGroupModalProps {
  name ?: string;
  onClose ?: () => void;
  onSubmit ?: (name : string) => void;
}

const InteractionsGroupModal : React.FC<InteractionsGroupModalProps> = (p) => {
  const [name, setName] = useState<string | undefined>(p.name);

  const handleValidate = () => {
    if(name) {
      p.onSubmit?.(name)
    }
    p.onClose?.();
  }
  return (
    <Modal
      title={"Edition des groupes d'intéractions"}
      onClose={p.onClose}
      controls={
        <TextButton disabled={!name?.trim()} onClick={handleValidate}>Validate</TextButton>
      }
    >
      <TextInput
        label="Nom du groupe d'intéractions"
        value={name}
        onChange={setName}
      />
    </Modal>
  )
}

export default InteractionsGroupModal;


