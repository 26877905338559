import { Light, Vec3 } from "../../../lib";
import BooleanInput from "./boolean-input";
import TextInput from "./text-input";
import TextSelect from "./text-select";
import Vec1Input from "./vec1-input";
import Vec3Input from "./vec3-input";


interface LightInputProps {
  light : Light,
  onChange : (light : Light) => void;
}

const LightInput : React.FC<LightInputProps> = (p) => {

  return (
    <>
    <TextSelect
      options={[{value : 'ambient', name : 'Ambient'}, {value : 'directional', name : 'Directional'}, {value : 'point', name : 'Point'}, {value : 'spot', name : 'Spot'}]}
      value={p.light.type}
      onChange={type => p.onChange({...p.light, type})}
    />
    <TextInput
      value={p.light.color}
      onChange={(color : string) => p.onChange({...p.light, color})}
      label="Color"
    />
    <Vec1Input
      value={p.light.intensity}
      onChange={(intensity : number) => p.onChange({...p.light, intensity})}
      label="Intensity"
      onDelete={() => p.onChange({...p.light, intensity : undefined})}
      inline
    />
    {
      ['point', 'spot', 'directional'].includes(p.light.type) &&
        <Vec3Input
          value={p.light.position}
          onChange={(position : Vec3) => p.onChange({...p.light, position})}
          labels={['Position X', 'Position Y', 'Position Z']}
          onDelete={() => p.onChange({...p.light, position : undefined})}
          inline
        />
    }
    {
      ['spot', 'directional'].includes(p.light.type) &&
        <Vec3Input
          value={p.light.target}
          onChange={(target : Vec3) => p.onChange({...p.light, target})}
          labels={['Target X', 'Target Y', 'Target Z']}
          onDelete={() => p.onChange({...p.light, target : undefined})}
          inline
        />
    }
    {
      ['point', 'spot', 'directional'].includes(p.light.type) &&
        <BooleanInput
          value={p.light.castShadow}
          onChange={(value : boolean) => p.onChange({...p.light, castShadow : value})}
          label="Cast Shadow"
          inline
        />
    }
    {
      ['point', 'spot'].includes(p.light.type) &&
        <Vec1Input
          value={p.light.distance}
          onChange={(distance : number) => p.onChange({...p.light, distance})}
          label="Distance"
          onDelete={() => p.onChange({...p.light, distance : undefined})}
          inline
        />
    }
    {
      ['point', 'spot'].includes(p.light.type) &&
        <Vec1Input
          value={p.light.decay}
          onChange={(decay : number) => p.onChange({...p.light, decay})}
          label="Decay"
          onDelete={() => p.onChange({...p.light, decay : undefined})}
          inline
        />
    }
    {
      ['spot'].includes(p.light.type) &&
        <Vec1Input
          value={p.light.angle}
          onChange={(angle : number) => p.onChange({...p.light, angle})}
          label="Angle (radians)"
          onDelete={() => p.onChange({...p.light, angle : undefined})}
          inline
        />
    }
    {
      ['spot'].includes(p.light.type) &&
        <Vec1Input
          value={p.light.penumbra}
          onChange={(penumbra : number) => p.onChange({...p.light, penumbra})}
          label="Penumbra"
          onDelete={() => p.onChange({...p.light, penumbra : undefined})}
          inline
        />
    }
    </>
  )

}

export default LightInput;