import { useState } from "react";
import { SceneObject } from "../../../lib";
import Modal from "../components/modal"
import TextSelect from "../components/text-select";
import TextButton from "../components/text-button";
import TextInput from "../components/text-input";
import { useModelStore } from "../../../store/store";

interface ObjectModalProps {
  obj ?: SceneObject;
  onClose ?: () => void;
  onSubmit ?: (obj : SceneObject) => void;
}

const ObjectModal : React.FC<ObjectModalProps> = (p) => {
  const [id, setId] = useState<string | undefined>(p.obj?.id);
  const [assetIndex, setAssetIndex] = useState<number | undefined>(p.obj?.asset);
  const model = useModelStore(state => state.model.getContent());

  const handleValidate = () => {
    if(id && assetIndex !== undefined) {
      p.onSubmit?.({
        id, asset: assetIndex
      })
    }
    p.onClose?.();
  }
  return (
    <Modal
      title={"Edition des objets"}
      onClose={p.onClose}
      controls={
        <TextButton disabled={!id || assetIndex === undefined} onClick={handleValidate}>Validate</TextButton>
      }
    >
      <TextInput
        label="Id de l'objet"
        value={id}
        onChange={setId}
        />
      <TextSelect
        label="Asset"
        options={model.assets.map((asset, index) => ({
          name : `[${asset.type}] ${asset.url.length < 15 ? asset.url : ('...' + asset.url.slice(-15))}`,
          value : `${index}`
        }))}
        value={assetIndex !== undefined ? `${assetIndex}` : undefined}
        onChange={(v) => setAssetIndex(parseInt(v))}
        />
      </Modal>
  )
}

export default ObjectModal

