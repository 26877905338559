import styled from "styled-components";
import { useModelActions } from "../../../store/hooks/use-actions";
import CloudDownloadIcon from "../../../assets/icons/cloud-download";
import { useState } from "react";
import OpenCloudFileModal from "../modals/open-cloud-file-modal";
import useStorageProvider from "../../../providers/storageprovider";
import Tooltip from "../components/tooltip";

const CloudDownloadButton : React.FC<{}> = (p) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const {load} = useModelActions('model');
  const storageProvider = useStorageProvider();

  const handleClickUpload = () => {
    setModalVisible(true)
  }

  const handleOpen = (file : string) => {
    storageProvider.getOne(file).then(content => {
      load(JSON.stringify(content));
    })
  }

  return (
    <>
      <Tooltip content="Download model from cloud">
        <Button onClick={handleClickUpload}><CloudDownloadIcon/></Button>
      </Tooltip>
      {modalVisible ? <OpenCloudFileModal onSubmit={handleOpen} onClose={() => setModalVisible(false)}/> : null}
    </>
  )
}

export default CloudDownloadButton;


const Button = styled.div`
  width : 3rem;
  height : 3rem;
  padding : 0.5rem;
`
