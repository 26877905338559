import styled from "styled-components";
import colors from "../../../contants/colors";
import { ReactNode, useState } from "react";
import transitions from "../../../contants/transitions";

interface AcordeonProps {
  panels : {label : string; node : ReactNode}[];
}

const Acordeon : React.FC<AcordeonProps> = (p) => {
  const [panelsStatus, setPanelsStatus] = useState<Record<string, boolean>>({})
  return (
    <Container>
      {
        p.panels.map(panel => (
          <Panel key={panel.label}>
            <PanelLabel
              onClick={() => setPanelsStatus(v => ({...v, [panel.label] : !v[panel.label]}))}
            >
              <span>{panel.label}</span>
              <Chevron className={panelsStatus[panel.label] ? 'visible' : ''} >▶︎</Chevron>
            </PanelLabel>
            <PanelContent
              className={panelsStatus[panel.label] ? 'visible' : ''}
            >
              {panel.node}
            </PanelContent>
          </Panel>
        ))
      }

    </Container>
  )
}

export default Acordeon;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
`

const Panel = styled.div`
`

const PanelLabel = styled.div`
  color : ${colors.text};
  background-color: ${colors.backgroundLight};
  display: flex;
  justify-content: space-between;
  padding : 0.7rem;
  font-size: 1.2rem;
  font-weight: bolder;
  text-transform: uppercase;
  cursor : pointer;
  position : sticky;
  top : 0;
`

const PanelContent = styled.div`
  padding : 0.5rem;
  display: none;

  &.visible {
    display: block;
  }
`

const Chevron = styled.div`
  transition: ${transitions.fast};

  &.visible {
    transform: rotate(-90deg);
  }
`