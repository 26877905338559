import colors from "../../contants/colors";
import IconProps from "./icon-interface";

const CloudUploadIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
  <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.9999 39.8104C25.4218 39.8092 24.8597 39.6204 24.398 39.2724C23.9364 38.9243 23.6002 38.4359 23.4399 37.8804C22.2637 33.7881 22.7613 29.3962 24.8233 25.6707C26.8852 21.9453 30.3427 19.1916 34.4349 18.0154C38.5272 16.8391 42.9192 17.3367 46.6446 19.3987C50.37 21.4607 53.1237 24.9181 54.2999 29.0104C54.4473 29.3573 54.5195 29.7315 54.5118 30.1083C54.5042 30.4851 54.4168 30.856 54.2555 31.1967C54.0942 31.5373 53.8627 31.8399 53.576 32.0846C53.2894 32.3294 52.9542 32.5106 52.5925 32.6165C52.2308 32.7224 51.8507 32.7505 51.4774 32.699C51.104 32.6475 50.7458 32.5175 50.4263 32.3176C50.1068 32.1177 49.8332 31.8524 49.6235 31.5392C49.4139 31.226 49.2729 30.872 49.2099 30.5004C48.8407 29.1251 48.2011 27.8371 47.3286 26.7118C46.4561 25.5864 45.3681 24.6461 44.1282 23.9458C42.8883 23.2456 41.5213 22.7994 40.107 22.6333C38.6927 22.4672 37.2595 22.5845 35.8911 22.9784C34.5227 23.3724 33.2465 24.035 32.137 24.9276C31.0274 25.8202 30.1069 26.9249 29.429 28.1772C28.7512 29.4295 28.3296 30.8043 28.189 32.2214C28.0484 33.6384 28.1915 35.0693 28.6099 36.4304C28.7223 36.8299 28.7397 37.2501 28.6607 37.6575C28.5816 38.0649 28.4084 38.4481 28.1547 38.7766C27.9011 39.105 27.5741 39.3696 27.2 39.5491C26.8258 39.7286 26.4149 39.8181 25.9999 39.8104Z" fill={color}/>
    <path d="M80.7699 48.4997C80.3192 48.4983 79.876 48.3849 79.4799 48.1697C78.8695 47.8176 78.4223 47.2393 78.2351 46.5599C78.0479 45.8805 78.1359 45.1547 78.4799 44.5397C79.3651 42.9402 79.9265 41.182 80.1321 39.3656C80.3377 37.5491 80.1836 35.7099 79.6784 33.953C79.1733 32.1962 78.327 30.556 77.188 29.1261C76.0489 27.6963 74.6394 26.5048 73.0399 25.6197C71.4404 24.7346 69.6823 24.1732 67.8658 23.9676C66.0493 23.7619 64.2102 23.9161 62.4533 24.4212C60.6964 24.9264 59.0562 25.7726 57.6264 26.9117C56.1965 28.0507 55.0051 29.4602 54.1199 31.0597C53.7534 31.6256 53.1863 32.0319 52.5325 32.1969C51.8788 32.362 51.1868 32.2736 50.5955 31.9494C50.0043 31.6253 49.5575 31.0895 49.3451 30.4496C49.1326 29.8097 49.17 29.1131 49.4499 28.4997C50.6277 26.2125 52.2524 24.1849 54.2278 22.537C56.2032 20.889 58.4892 19.6541 60.9505 18.9055C63.4117 18.1568 65.9981 17.9097 68.5566 18.1786C71.1151 18.4475 73.5936 19.2271 75.8454 20.4711C78.0972 21.7151 80.0765 23.3983 81.6661 25.421C83.2557 27.4437 84.4233 29.7648 85.0998 32.2468C85.7762 34.7289 85.9478 37.3214 85.6043 39.8709C85.2607 42.4205 84.4092 44.8752 83.0999 47.0897C82.8738 47.5122 82.5382 47.866 82.1282 48.1141C81.7183 48.3622 81.2491 48.4954 80.7699 48.4997Z" fill={color}/>
    <path d="M84.5499 73.7799H84.4699C83.7617 73.7799 83.0826 73.4986 82.5819 72.9979C82.0812 72.4972 81.7999 71.818 81.7999 71.1099C81.7999 70.4018 82.0812 69.7226 82.5819 69.2219C83.0826 68.7212 83.7617 68.4399 84.4699 68.4399H84.5399C86.0849 68.4454 87.6105 68.0946 88.9979 67.4148C90.3854 66.7349 91.5974 65.7443 92.5399 64.5199C93.4779 63.3316 94.127 61.9417 94.4359 60.4596C94.7448 58.9775 94.7051 57.444 94.3199 55.9799C94.3012 55.892 94.2778 55.8052 94.2499 55.7199C93.4873 53.0761 91.7072 50.8428 89.3 49.5099C86.8929 48.177 84.0553 47.8533 81.4099 48.6099C81.0644 48.7375 80.6962 48.792 80.3286 48.7699C79.961 48.7478 79.602 48.6497 79.2743 48.4816C78.9466 48.3136 78.6574 48.0793 78.425 47.7937C78.1925 47.508 78.0219 47.1772 77.9239 46.8222C77.826 46.4672 77.8028 46.0958 77.8559 45.7313C77.909 45.3669 78.0371 45.0175 78.2323 44.7052C78.4274 44.3929 78.6853 44.1245 78.9896 43.917C79.2938 43.7095 79.6379 43.5675 79.9999 43.4999C83.9978 42.363 88.2833 42.8557 91.9189 44.8703C95.5545 46.8849 98.2443 50.2573 99.3999 54.2499C99.3999 54.3799 99.4699 54.5099 99.5099 54.6399C100.1 56.8938 100.161 59.2538 99.6873 61.5351C99.2137 63.8164 98.2186 65.9572 96.7799 67.7899C95.3375 69.6597 93.4842 71.1725 91.3635 72.2112C89.2427 73.2499 86.9113 73.7867 84.5499 73.7799Z" fill={color}/>
    <path d="M17.83 73.9903C14.3215 73.9884 10.8932 72.941 7.98274 70.9817C5.07231 69.0224 2.81184 66.2402 1.48995 62.9903C0.0402602 59.3269 -0.366524 55.3337 0.314706 51.4533C0.995936 47.5729 2.73856 43.957 5.34935 41.0065C7.96014 38.0561 11.3371 35.8863 15.1057 34.7379C18.8744 33.5894 22.8874 33.5072 26.7 34.5003C27.3487 34.7092 27.8929 35.1584 28.2212 35.7556C28.5494 36.3529 28.6368 37.0531 28.4655 37.7128C28.2942 38.3724 27.8772 38.9416 27.2998 39.3037C26.7224 39.6658 26.0284 39.7933 25.36 39.6603C22.4991 38.9144 19.4875 38.9758 16.6594 39.8376C13.8313 40.6994 11.2973 42.3279 9.33858 44.5425C7.37986 46.7571 6.07304 49.471 5.56314 52.3832C5.05324 55.2954 5.3602 58.2919 6.44995 61.0403C7.37556 63.297 8.95226 65.2269 10.979 66.584C13.0058 67.9412 15.3908 68.6639 17.83 68.6603H17.88C18.5585 68.7019 19.1957 69.0007 19.6615 69.4959C20.1273 69.9912 20.3866 70.6454 20.3866 71.3253C20.3866 72.0051 20.1273 72.6594 19.6615 73.1546C19.1957 73.6498 18.5585 73.9487 17.88 73.9903H17.83Z" fill={color}/>
    <path d="M49.9999 49.2798C50.4128 49.3005 50.8014 49.4813 51.0833 49.7837C51.3652 50.0861 51.5182 50.4865 51.5099 50.8998V81.1098C51.5182 81.5232 51.3652 81.9235 51.0833 82.2259C50.8014 82.5284 50.4128 82.7091 49.9999 82.7298C49.793 82.7352 49.5872 82.699 49.3945 82.6235C49.2018 82.548 49.0261 82.4347 48.8779 82.2902C48.7298 82.1458 48.612 81.9731 48.5316 81.7824C48.4512 81.5916 48.4098 81.3868 48.4099 81.1798V50.8298C48.4098 50.6229 48.4512 50.418 48.5316 50.2273C48.612 50.0366 48.7298 49.8639 48.8779 49.7194C49.0261 49.5749 49.2018 49.4616 49.3945 49.3861C49.5872 49.3106 49.793 49.2745 49.9999 49.2798Z" fill={color}/>
    <path d="M49.9999 83.6198C49.3527 83.6198 48.7321 83.3627 48.2745 82.9051C47.8169 82.4475 47.5599 81.8269 47.5599 81.1798V50.8298C47.5576 50.5031 47.6221 50.1793 47.7493 49.8784C47.8766 49.5775 48.0639 49.3057 48.2999 49.0798C48.5302 48.8502 48.8053 48.6704 49.1081 48.5517C49.4109 48.433 49.7349 48.3779 50.0599 48.3898C50.7027 48.4208 51.3086 48.6995 51.7504 49.1674C52.1923 49.6354 52.4358 50.2562 52.4299 50.8998V81.1098C52.4358 81.7533 52.1923 82.3742 51.7504 82.8421C51.3086 83.3101 50.7027 83.5888 50.0599 83.6198H49.9999ZM49.9999 50.1598C49.8276 50.1607 49.6625 50.2288 49.5399 50.3498C49.4762 50.4124 49.4257 50.4871 49.3914 50.5696C49.3571 50.652 49.3395 50.7405 49.3399 50.8298V81.1798C49.3385 81.2677 49.3557 81.355 49.3901 81.436C49.4245 81.5169 49.4756 81.5897 49.5399 81.6498C49.603 81.7121 49.6781 81.761 49.7605 81.7936C49.843 81.8263 49.9312 81.842 50.0199 81.8398C50.1993 81.8221 50.3653 81.7368 50.4841 81.6012C50.603 81.4657 50.6658 81.29 50.6599 81.1098V50.8998C50.666 50.7185 50.6035 50.5416 50.4849 50.4044C50.3662 50.2672 50.2001 50.1798 50.0199 50.1598H49.9999Z" fill={color}/>
    <path d="M48.2701 47.2401C48.739 46.8007 49.3575 46.5562 50.0001 46.5562C50.6427 46.5562 51.2612 46.8007 51.7301 47.2401L62.8501 57.7501C63.1328 58.0361 63.2933 58.4208 63.2976 58.823C63.3019 59.2251 63.1498 59.6132 62.8733 59.9053C62.5968 60.1973 62.2176 60.3705 61.8159 60.3882C61.4141 60.4059 61.0212 60.2667 60.7201 60.0001L51.0701 50.8901C50.7818 50.615 50.3986 50.4615 50.0001 50.4615C49.6016 50.4615 49.2184 50.615 48.9301 50.8901L39.2801 60.0001C39.1339 60.1479 38.9596 60.2649 38.7673 60.3439C38.575 60.423 38.3688 60.4626 38.161 60.4604C37.9531 60.4581 37.7478 60.4141 37.5573 60.3309C37.3668 60.2477 37.195 60.127 37.0521 59.9761C36.9092 59.8251 36.7981 59.6469 36.7254 59.4521C36.6528 59.2574 36.62 59.05 36.6292 58.8423C36.6383 58.6346 36.6892 58.4309 36.7786 58.2433C36.8681 58.0556 36.9944 57.8879 37.1501 57.7501L48.2701 47.2401Z" fill={color}/>
    <path d="M38.22 61.31H38.15C37.5021 61.2918 36.8876 61.0187 36.44 60.55C35.9981 60.0781 35.7606 59.4506 35.7794 58.8044C35.7981 58.1582 36.0715 57.5455 36.54 57.1L47.66 46.6C48.2928 46.003 49.13 45.6704 50 45.6704C50.87 45.6704 51.7071 46.003 52.34 46.6L63.46 57.1C63.9281 57.5439 64.2013 58.1551 64.22 58.8C64.2327 59.121 64.1808 59.4413 64.0675 59.7419C63.9541 60.0425 63.7815 60.3173 63.56 60.55C63.339 60.783 63.0742 60.97 62.7807 61.1004C62.4873 61.2309 62.171 61.3021 61.85 61.31C61.5305 61.3219 61.212 61.2697 60.9131 61.1563C60.6142 61.0429 60.3411 60.8708 60.11 60.65L50.46 51.5C50.3368 51.3803 50.1718 51.3133 50 51.3133C49.8282 51.3133 49.6632 51.3803 49.54 51.5L39.9 60.65C39.4449 61.0766 38.8438 61.3128 38.22 61.31ZM48.88 47.89L37.76 58.39C37.6363 58.5116 37.5646 58.6765 37.56 58.85C37.5545 58.9809 37.588 59.1104 37.6564 59.2222C37.7248 59.3339 37.8248 59.4228 37.9438 59.4775C38.0629 59.5321 38.1955 59.5502 38.3248 59.5293C38.4541 59.5083 38.5743 59.4494 38.67 59.36L48.32 50.25C48.7745 49.8218 49.3755 49.5833 50 49.5833C50.6245 49.5833 51.2254 49.8218 51.68 50.25L61.33 59.36C61.4584 59.477 61.6262 59.5413 61.8 59.54C61.888 59.5374 61.9746 59.5175 62.0549 59.4814C62.1352 59.4453 62.2076 59.3938 62.268 59.3297C62.3283 59.2656 62.3755 59.1903 62.4067 59.108C62.4379 59.0256 62.4526 58.938 62.45 58.85C62.4417 58.6754 62.3665 58.5106 62.24 58.39L51.12 47.89C50.8162 47.606 50.4158 47.448 50 47.448C49.5841 47.448 49.1838 47.606 48.88 47.89Z" fill={color}/>
  </svg>
  )
}

export default CloudUploadIcon;

