import { ReactElement, ReactNode, useRef, useState } from "react";
import styled from "styled-components";
import colors from "../../../contants/colors";

interface TooltipProps {
  content : ReactNode;
  children : ReactNode;
}

const Tooltip : React.FC<TooltipProps> = (p) => {
  const timerRef = useRef<any>();
  const [longHover, setLongHover] = useState<boolean>(false);

  const handleMouseEnter = () => {
    if(timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {setLongHover(true)}, 400)
  }

  const handleMouseLeave = () => {
    if(timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setLongHover(false);
  }

  return (
    <Wrapper onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {p.children}
      <div className={"tooltip " + (longHover ? 'long-hover' : '')}>{p.content}</div>
    </Wrapper>
  )
}

export default Tooltip;

const Wrapper = styled.a`
  position: relative;

  & .tooltip {
    visibility: hidden;
    background-color: ${colors.lightBackground};
    color: ${colors.darkText};
    text-align: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border : 1px solid ${colors.darkText};
    white-space: nowrap;

    /* Position the tooltip text - see examples below! */
    position: fixed;
    z-index: 100;
  }

  &:hover .tooltip.long-hover {
    visibility: visible;
  }
`