import Layout from "../components/layout/layout";
import Scene from "../components/controls/panels/scene";
import Output from "../components/controls/panels/output";
import Navigator from "../components/controls/panels/navigator";
import Editor from "../components/controls/panels/editor";
import DownloadButton from "../components/controls/tools/download-button";
import UploadButton from "../components/controls/tools/upload-button";
import ConnectButton from "../components/controls/tools/connect-button";
import CloudDownloadButton from "../components/controls/tools/cloud-download-button";
import CloudUploadButton from "../components/controls/tools/cloud-upload-button";
import FreezeButton from "../components/controls/tools/freeze-button";
import ExportToGlb from "../components/controls/tools/export-to-gbl";
import LogoutButton from "../components/controls/tools/logout-button";

const MainPage = () => {
  return (
    <Layout
      left={<Navigator />}
      bottom={<Output />}
      right={<Editor />}
      tools={[
        <CloudDownloadButton key="CloudDownloadButton" />,
        <CloudUploadButton key="CloudUploadButton" />,
        <DownloadButton key="DownloadButton"/>,
        <UploadButton key="UploadButton"/>,
        <ConnectButton key="ConnectButton"/>,
        <FreezeButton key="FreezeButton" />,
        <ExportToGlb key="ExportToGlb" />
      ]}
      rightTools={[
        <LogoutButton key="LogoutButton" />
      ]}
    >
      <Scene />
    </Layout>
  )
}

export default MainPage;
