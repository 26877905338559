import styled from "styled-components";
import { useModelActions } from "../../../store/hooks/use-actions";
import CloudUploadIcon from "../../../assets/icons/cloud-upload";
import OpenCloudFileModal from "../modals/open-cloud-file-modal";
import { useState } from "react";
import useStorageProvider from "../../../providers/storageprovider";
import { useModelStore } from "../../../store/store";
import Tooltip from "../components/tooltip";

const CloudUploadButton : React.FC<{}> = (p) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const model = useModelStore(state => state.model);
  const storageProvider = useStorageProvider();
  const handleClickUpload = () => {
    setModalVisible(true)
  }

  const handleStore = (file : string) => {
    const onOverwrite = (name : string) => new Promise<boolean>((resolve, _) => {
      resolve(window.confirm(`File ${name} exists. Overwrite?`))
    })

    storageProvider.store(file, JSON.stringify(model.getContent()), onOverwrite).catch((error) => {
      console.error(error);
      alert('Unable to store file, see error logs')
    })
  }

  return (
    <>
      <Tooltip content="Send model to cloud">
        <Button onClick={handleClickUpload}><CloudUploadIcon/></Button>
      </Tooltip>
      {modalVisible ? <OpenCloudFileModal selectName onSubmit={handleStore} onClose={() => setModalVisible(false)}/> : null}
    </>
  )
}

export default CloudUploadButton;


const Button = styled.div`
  width : 3rem;
  height : 3rem;
  padding : 0.5rem;
`
