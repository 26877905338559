import List from "../components/list";
import { useModelStore } from "../../../store/store";
import { useState } from "react";
import LightModal from "../modals/light-modal";
import { useModelActions } from "../../../store/hooks/use-actions";
import TextButton from "../components/text-button";

const LightsPanel : React.FC<{}> = (p) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalItem, setModalItem] = useState<number | undefined>();
  const {addLight, updateLight, removeLight} = useModelActions('lights');
  const {selectItem} = useModelActions('editor');
  const itemSelection = useModelStore(state => state.itemSelection);

  const model = useModelStore(state => state.model);
  const defaultLights = model.lights() || [];

  const handleAddLight = () => {
    setShowModal(true);
    setModalItem(undefined);
  }

  const handleEdit = (index : number) => {
    setShowModal(true);
    setModalItem(index);
  }

  const handleSubmit = (id : string) => {
    if(typeof modalItem === 'number') {
      updateLight(modalItem, {id});
    }
    else {
      addLight({id, type : 'ambient'})
    }
  }


  const handleRemove = (index : number) => {
    if(window.confirm(`Confirmer la suppression de la lumière`)) {
      removeLight(index);
    }
  }

  const handleSelect = (index : number) => {
    selectItem({type : 'light', index});
  }

  const isSelected = (index : number) => {
    return !!itemSelection && itemSelection.type === 'light' && itemSelection.index === index
  }

  const handleAddDefaultLights = () => {
    addLight({id : 'ambient', type : 'ambient', intensity : Math.PI / 2});
    addLight({id : 'spot', type : 'spot', position : [10, 10, 10], angle : 0.15, penumbra : 1, decay : 0, intensity : Math.PI});
    addLight({id : 'point', type : 'point', position : [-10, -10, -10], decay : 0, intensity : Math.PI});
  }

  return (
    <>
      <List
        items={defaultLights.map(obj => `[${obj.id || '-'}]`)}
        onAdd={handleAddLight}
        onEdit={handleEdit}
        onDelete={handleRemove}
        onSelect={handleSelect}
        isSelected={isSelected}
        tools={[
          {label : 'Add Default Lights', action : handleAddDefaultLights}
        ]}
      />
      {showModal && <LightModal id={modalItem !== undefined ? defaultLights[modalItem].id : undefined} onClose={() => setShowModal(false)} onSubmit={handleSubmit}/>}
    </>
  )
}

export default LightsPanel;