
import colors from "../../contants/colors";
import IconProps from "./icon-interface";

const GLBIcon : React.FC<IconProps & {onClick?:()=>void}> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg onClick={p.onClick} className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 24 24">
      <title>video-3d</title>
      <path fill={color} d="M5,7H9A2,2 0 0,1 11,9V15A2,2 0 0,1 9,17H5V15H9V13H6V11H9V9H5V7M13,7H16A3,3 0 0,1 19,10V14A3,3 0 0,1 16,17H13V7M16,15A1,1 0 0,0 17,14V10A1,1 0 0,0 16,9H15V15H16Z" />
    </svg>
  )
}

export default GLBIcon;

