import colors from "../../contants/colors";
import IconProps from "./icon-interface";

const EditIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.48917 15.9833C6.424 15.9842 6.35899 15.9768 6.29571 15.9614C6.19488 15.9362 6.10011 15.8914 6.01685 15.8297C5.93359 15.768 5.86349 15.6906 5.81061 15.6019C5.75772 15.5132 5.7231 15.415 5.70873 15.313C5.69436 15.211 5.70053 15.1072 5.72689 15.0076L6.77831 11.0204C6.80932 10.9012 6.8672 10.7905 6.94759 10.6966L16.6491 1.09168C16.7969 0.945429 16.9973 0.863281 17.2063 0.863281C17.4153 0.863281 17.6157 0.945429 17.7636 1.09168L20.737 4.03647C20.7762 4.07333 20.8115 4.11416 20.8421 4.1583C20.9413 4.30898 20.985 4.48892 20.9658 4.66779C20.9467 4.84665 20.8659 5.01351 20.737 5.14024L11.0723 14.7118C10.9744 14.812 10.8508 14.884 10.7148 14.9201L6.68999 15.9614C6.62425 15.9771 6.55677 15.9844 6.48917 15.9833ZM17.2063 2.74838L8.25135 11.6171L7.59421 14.1089L10.1092 13.4623L19.0652 4.58836L17.2063 2.74838Z" fill={color}/>
      <path d="M18.932 16.3895C19.2194 13.957 19.3111 11.5057 19.2065 9.05881C19.204 9.00114 19.2137 8.94359 19.2348 8.88981C19.256 8.83604 19.2882 8.78721 19.3295 8.74642L20.363 7.72282C20.3915 7.69525 20.4274 7.67627 20.4663 7.66809C20.5053 7.65991 20.5459 7.66286 20.5832 7.67661C20.6206 7.69036 20.6532 7.71434 20.6773 7.74575C20.7014 7.77716 20.7161 7.81471 20.7195 7.85403C20.9142 10.7605 20.8403 13.6786 20.4987 16.5718C20.3729 17.5939 19.9023 18.544 19.1632 19.2682C18.4242 19.9924 17.4598 20.4483 16.4265 20.562C12.7518 20.9655 9.04337 20.9655 5.36869 20.562C4.33538 20.4481 3.37119 19.9919 2.6323 19.2675C1.89341 18.5431 1.42311 17.5929 1.29758 16.5707C0.861744 12.8801 0.861744 9.15175 1.29758 5.46113C1.42338 4.43901 1.89393 3.48888 2.63301 2.76467C3.37208 2.04046 4.3364 1.58458 5.36974 1.47088C8.1585 1.16461 10.9683 1.09042 13.7696 1.24908C13.8093 1.25228 13.8474 1.26662 13.8793 1.29044C13.9111 1.31426 13.9355 1.34657 13.9495 1.3836C13.9635 1.42062 13.9666 1.46083 13.9583 1.49951C13.9501 1.5382 13.931 1.57378 13.9031 1.60208L12.859 2.63609C12.8178 2.67693 12.7685 2.70885 12.7142 2.72983C12.6599 2.7508 12.6018 2.76037 12.5436 2.75792C10.206 2.67922 7.86577 2.76796 5.54112 3.02345C4.86209 3.09866 4.22848 3.39833 3.74258 3.87407C3.25668 4.34981 2.94687 4.97385 2.86315 5.64544C2.4415 9.21568 2.4415 12.8224 2.86315 16.3927C2.94745 17.0643 3.25791 17.6883 3.7444 18.1637C4.23089 18.639 4.865 18.9381 5.54428 19.0126C9.10227 19.403 12.6929 19.403 16.2509 19.0126C16.9307 18.938 17.5651 18.6384 18.0517 18.1624C18.5382 17.6864 18.8484 17.0618 18.932 16.3895Z" fill={color}/>
    </svg>
  )
}

export default EditIcon;
