import styled from "styled-components";
import { useInteractionsState, useUgla3dStateManager } from "../../../lib";
import Typography from "../components/typography";
import TextButton from "../components/text-button";
import colors from "../../../contants/colors";
import TextInput from "../components/text-input";
import TextSelect from "../components/text-select";
import Acordeon from "../components/acordeon";
import AssetInput from "../components/asset-input";
import ModsInput from "../components/mods-input";
import { useState } from "react";
import ObjectPathInput from "../components/object-path-input";
import BooleanInput from "../components/boolean-input";

interface ControllerGuesserProps {

}

const ExecuteButton : React.FC<{callback : () => void}> = (p) => {
  return (
    <ExecuteContainer>
      <TextButton onClick={p.callback}>Execute</TextButton>
    </ExecuteContainer>
  )
}

const ControllerGuesser : React.FC<ControllerGuesserProps> = (p) => {
  const interactions = useInteractionsState().filter(i => i.type === 'hidden');
  const stateManager = useUgla3dStateManager();

  const [apiPayload, setApiPayload] = useState<Record<string, any>>({})

  const handleUpdateApiPayload = (key : string, value : any) => {
    setApiPayload(values => ({
      ...values,
      [key] : value
    }))
  }

  const handleHiddenInteraction = (id : string) => {
    stateManager.interact(id);
  }

  return (
    <Container>
      <Column>
        <Typography variant="mediumTitle">Hidden interactions</Typography>
        {
          interactions.length === 0 ?
            <div style={{paddingTop : '1rem'}}>
              <Typography variant="label">No hidden interactions available</Typography>
            </div> :
            null
        }
      {
        interactions.map(i => (
          <Interaction key={i.id}>
            <TextButton onClick={() => handleHiddenInteraction(i.id)}>{i.id}</TextButton>
          </Interaction>
        ))
      }

      </Column>
      <Column>
        <Typography variant="mediumTitle">Controller API</Typography>
        <Acordeon
          panels={[
            {label : 'Interaction mode', node : (
              <div>
                <TextInput label="Mode" onChange={v => handleUpdateApiPayload('interaction-mode', v)} value={apiPayload['interaction-mode']}/>
                <ExecuteButton
                  callback={() => {
                    apiPayload['interaction-mode'] && stateManager.selectInteractionsGroups(apiPayload['interaction-mode'])
                  }}
                />
              </div>
            )},
            {label : 'Add Interactions Group', node : (
              <div>
                <TextInput label="Group name" onChange={v => handleUpdateApiPayload('add-interactions-group-name', v)} value={apiPayload['add-interactions-group-name']}/>
                <ExecuteButton
                  callback={() => {
                    apiPayload['add-interactions-group-name'] && stateManager.addInteractionsGroup(apiPayload['add-interactions-group-name'])
                  }}
                />
              </div>
            )},
            {label : 'Remove Interactions Group', node : (
              <div>
                <TextInput label="Group name" onChange={v => handleUpdateApiPayload('remove-interactions-group-name', v)} value={apiPayload['remove-interactions-group-name']}/>
                <BooleanInput label="Regex" onChange={v => handleUpdateApiPayload('remove-interactions-group-regex', v)} value={apiPayload['remove-interactions-group-regex']}/>
                <ExecuteButton
                  callback={() => {
                    apiPayload['remove-interactions-group-name'] && stateManager.removeInteractionsGroup(apiPayload['remove-interactions-group-name'], apiPayload['remove-interactions-group-regex'])
                  }}
                />
              </div>
            )},
            {label : 'Show', node : (
              <div>
                <ObjectPathInput label="Path" mode="both" onChange={v => handleUpdateApiPayload('show-path', v)} value={apiPayload['show-path']}/>
                <ExecuteButton
                  callback={() => {
                    apiPayload['show-path'] && stateManager.show(apiPayload['show-path'])
                  }}
                />
              </div>
            )},
            {label : 'Hide', node : (
              <div>
                <ObjectPathInput label="Path" mode="both" onChange={v => handleUpdateApiPayload('hide-path', v)} value={apiPayload['hide-path']}/>
                <ExecuteButton
                  callback={() => {
                    console.log(apiPayload['hide-path'])
                    apiPayload['hide-path'] && stateManager.hide(apiPayload['hide-path'])
                  }}
                />
              </div>
            )},
            {label : 'Toggle', node : (
              <div>
                <ObjectPathInput label="Path" mode="both" onChange={v => handleUpdateApiPayload('toggle-path', v)} value={apiPayload['toggle-path']}/>
                <ExecuteButton
                  callback={() => {
                    apiPayload['toggle-path'] && stateManager.toggle(apiPayload['toggle-path'])
                  }}
                />
              </div>
            )},
            {label : 'Add', node : (
              <div>
                <TextInput label="ObjectId" onChange={v => handleUpdateApiPayload('add-objectId', v)} value={apiPayload['add-objectId']}/>
                <AssetInput label="Asset" onChange={v => handleUpdateApiPayload('add-asset', v)} value={apiPayload['add-asset']}/>
                <Typography variant="mediumTitle">Mods</Typography>
                <ModsInput onChange={v => handleUpdateApiPayload('add-mods', v)} value={apiPayload['add-mods']}/>
                <ExecuteButton
                  callback={() => {
                    if(apiPayload['add-objectId'] && apiPayload['add-asset']) {
                      stateManager.addObjectByReference({
                        id : apiPayload['add-objectId'],
                        asset : apiPayload['add-asset'],
                        mods : apiPayload['add-mods']
                      })
                    }
                  }}
                />
              </div>
            )},
            {label : 'Add external asset', node : (
              <div>
                <TextInput label="ObjectId" onChange={v => handleUpdateApiPayload('add-external-objectId', v)} value={apiPayload['add-external-objectId']}/>
                <TextSelect label="Type"  onChange={v => handleUpdateApiPayload('add-external-type', v)} value={apiPayload['add-external-type']} options={[{name : "2D", value : "2D"}, {name : "3D", value : "3D"}]}/>
                <TextInput label="URL" onChange={v => handleUpdateApiPayload('add-external-url', v)} value={apiPayload['add-external-url']}/>
                <Typography variant="mediumTitle">Mods</Typography>
                <ModsInput onChange={v => handleUpdateApiPayload('add-external-mods', v)} value={apiPayload['add-external-mods']}/>
                <ExecuteButton
                  callback={() => {
                    if(apiPayload['add-external-objectId'] && apiPayload['add-external-type'] && apiPayload['add-external-url']) {
                      stateManager.addObject({
                        id : apiPayload['add-external-objectId'],
                        asset : {
                          type : apiPayload['add-external-type'],
                          url : apiPayload['add-external-url']
                        },
                        mods : apiPayload['add-external-mods']
                      })
                    }
                  }}
                />
              </div>
            )},
            {label : 'Remove', node : (
              <div>
                <TextInput label="ObjectId" onChange={v => handleUpdateApiPayload('remove-objectId', v)} value={apiPayload['remove-objectId']}/>
                <BooleanInput label="Regex" value={apiPayload['remove-regex']} onChange={regex => handleUpdateApiPayload('remove-regex', regex)} />
                <ExecuteButton
                  callback={() => {
                    if(apiPayload['remove-objectId']) {
                      stateManager.removeObject(apiPayload['remove-objectId'], {regex : apiPayload['remove-regex']})
                    }
                  }}
                />
              </div>
            )},
            {label : 'Mods', node : (
              <div>
                <TextInput label="Modification id" onChange={v => handleUpdateApiPayload('mods-id', v)} value={apiPayload['mods-id']}/>
                <ModsInput onChange={v => handleUpdateApiPayload('mods', v)} value={apiPayload['mods']}/>
                <ExecuteButton
                  callback={() => {
                    if(apiPayload['mods-id'] && apiPayload['mods']) {
                      stateManager.setModsByReference(apiPayload['mods-id'], apiPayload['mods'])
                    }
                  }}
                />
              </div>
            )},
            {label : 'Reset mod', node : (
              <div>
                  <TextInput label="Modification id" onChange={v => handleUpdateApiPayload('reset-mods-id', v)} value={apiPayload['reset-mods-id']}/>
                  <BooleanInput label="Regex" value={apiPayload['reset-mods-regex']} onChange={regex => handleUpdateApiPayload('reset-mods-regex', regex)} />
                  <ExecuteButton
                    callback={() => {
                      if(apiPayload['reset-mods-id']) {
                        stateManager.resetMods(apiPayload['reset-mods-id'], {regex : apiPayload['reset-mods-regex']})
                      }
                    }}
                  />
              </div>
            )},
            {label : 'Animate', node : (<div></div>)}
          ]}
        />
      </Column>
    </Container>
  )
}

export default ControllerGuesser;

const Container = styled.div`
  display : flex;
`

const Column = styled.div`
  flex : 1;
  padding : 1;
`

const Interaction = styled.div`
  margin-top : 1rem;
  padding-bottom : 0.5rem;
`

const ExecuteContainer = styled.div`
  margin-bottom : 1rem;
  margin-top : 1rem;
  padding-top : 1rem;
  text-align: right;
  border-top: 1px solid ${colors.backgroundMiddle};
`



/*
              {
                i.actions?.map(a => (
                  a.type === 'add-external' ?
                  <InteractionRow>
                    <TextInput label="ObjectId"/>
                    <TextSelect label="type" options={[{name : '2D', value : '2D'}, {name : '3D', value : "3D"}]} />
                    <TextInput label="URL"/>
                  </InteractionRow> :
                  a.type === 'mod-external' ?
                  <InteractionRow>

                  </InteractionRow> : null
                ))
              }

*/