import { useModelStore } from "../../../store/store";
import { useControlState, Viewer } from "../../../lib"
import { useModelActions } from "../../../store/hooks/use-actions";
import { useState } from "react";
import Responsive from "../components/responsive";
import styled from "styled-components";
import colors from "../../../contants/colors";
import TextButton from "../components/text-button";

const Scene : React.FC<{}> = (p) => {
  const selectionActive = useModelStore(state => state.objectSelection.active);
  const selectedPaths = useModelStore(state => state.objectSelection.selected);
  const {selectObject} = useModelActions('editor');
  const [scale, setScale] = useState<number>(1);
  const [control, setControl] = useControlState();
  const [controlHeight, setControlHeight] = useState<boolean>(false);

  const handleClick = (e : any) => {
    e.stopPropagation();

    if(selectionActive) {
      const obj = e.object;
      const path = obj?.userData?.objectPath;
      if(obj && path) {
        selectObject(path);
      }
    }
  }

  return (
    <Container>
      <Responsive
        onScaleChange={setScale}
      >
        <Viewer
          scale={scale}
          onClick={handleClick}
          selection={selectionActive ? selectedPaths : undefined}
          controlHeight={controlHeight}
        />
      </Responsive>
      <ControllerTools>
        {control === 'walk' && (
          <Button onClick={() => setControlHeight(!controlHeight)}>
            {controlHeight ? 'Camera height' : 'Walk'}
          </Button>
        )}
      </ControllerTools>
    </Container>
  );
}

export default Scene;

const Container = styled.div`
  position : relative;
  width : 100%;
  height : 100%;
  display: flex;
  justify-content : center;
  align-items: center;
  overflow: hidden;
  background-color: ${colors.backgroundMiddle};
`;

const ControllerTools = styled.div`
  position : absolute;
  top : 0;
  right : 0;
`;

const Button = styled.button`
  background-color: ${colors.backgroundMiddle};
  color: ${colors.text};
  border : none;
  outline : none;
`;