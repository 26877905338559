import * as THREE from 'three';
import { Immutable } from 'immer';
import { Light } from "../model/ugla-filetype"
import { useThree } from '@react-three/fiber';
import { useEffect, useState, useRef } from 'react';
import { useViewerStore } from '../store/store';
import { useViewerActions } from '../store/hooks/use-actions';

interface LightProps {
  light : Immutable<Light>
}

const LightObject : React.FC<LightProps> = (p) => {
  const scene = useThree((state) => state.scene);
  const [target, setTarget] = useState<THREE.Object3D | undefined>(undefined);
  const lightRef = useRef<any>(null);
  const {addNative, removeNative} = useViewerActions('model');

  // Create a target object for the light
  useEffect(() => {
    const target = p.light.target ? new THREE.Object3D() : undefined;

    if(target && p.light.target) {
      target.position.set(...p.light.target);
      scene.add(target);
      setTarget(target);
    }

    return () => {
      if(target) {
        scene.remove(target);
      }
    }
  }, [scene, p.light.target]);

  useEffect(() => {
    if(lightRef.current) {
      addNative(p.light.id, lightRef.current);
      return () => {
        if(lightRef.current) {
          removeNative(p.light.id);
        }
      }
    }
  }, [lightRef.current]);

  switch(p.light.type) {
    case 'ambient' :
      return (
        <ambientLight
          ref={lightRef}
          intensity={p.light.intensity}
          color={p.light.color}
        />
      )
    case 'directional' :
      return (
        <directionalLight
          ref={lightRef}
          intensity={p.light.intensity}
          color={p.light.color}
          position={p.light.position}
          castShadow={p.light.castShadow}
          target={target}
        />
      )
    case 'point' :
      return (
        <pointLight
          ref={lightRef}
          intensity={p.light.intensity}
          color={p.light.color}
          position={p.light.position}
          castShadow={p.light.castShadow}
          distance={p.light.distance}
          decay={p.light.decay}
        />
      )
    case 'spot' :
      return (
        <spotLight
          ref={lightRef}
          intensity={p.light.intensity}
          color={p.light.color}
          position={p.light.position}
          castShadow={p.light.castShadow}
          distance={p.light.distance}
          decay={p.light.decay}
          angle={p.light.angle}
          penumbra={p.light.penumbra}
          target={target}
        />
      )
  }
}

export default LightObject;
