import styled from "styled-components";
import colors from "../../../contants/colors";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useModelStore } from "../../../store/store";
import { useState } from "react";
import List from "../components/list";
import InteractionsGroupModal from "../modals/interactions-group-modal";

const InteractionsPanel : React.FC<{}> = (p) => {
  const model = useModelStore(state => state.model);
  const interactionsGroupsNames = model.interactionsGroupsNames();

  const {selectItem} = useModelActions('editor');
  const {createInteractionGroup, deleteInteractionGroup, updateInteractionGroup} = useModelActions('interactions');
  const itemSelection = useModelStore(state => state.itemSelection);

  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [modalItem, setModalItem] = useState<number | undefined>();


  const handleAdd = () => {
    setModalItem(undefined);
    setModalVisibility(true);
  }

  const handleEdit = (index : number) => {
    setModalItem(index);
    setModalVisibility(true);
  }

  const handleSubmit = (name :string) => {
    if(typeof modalItem === 'number') {
      updateInteractionGroup(interactionsGroupsNames[modalItem], name);
    }
    else {
      createInteractionGroup(name)
    }
  }

  const handleRemove = (index : number) => {
    if(window.confirm(`Confirmer la suppression du groupe d'interactions`)) {
      deleteInteractionGroup(interactionsGroupsNames[index]);
    }
  }

  const handleSelect = (index : number) => {
    if(index === -1) {
      selectItem({type : 'defaultInteractionsGroup'})
    }
    else {
      selectItem({type : 'interactions', id : interactionsGroupsNames[index]})
    }
  }

  const isSelected = (index : number) => {
    return !!itemSelection && (
      (itemSelection.type === 'interactions' && itemSelection.id === interactionsGroupsNames[index]) ||
      (itemSelection.type === 'defaultInteractionsGroup' && index === -1)
    )
  }


  return (
    <Container>
      <Item onClick={() => handleSelect(-1)} className={isSelected(-1) ? 'selected' : ''}>Default interactions</Item>
      <List
        items={interactionsGroupsNames}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleRemove}
        onSelect={handleSelect}
        isSelected={isSelected}
      />
      {modalVisibility && <InteractionsGroupModal name={modalItem !== undefined ? interactionsGroupsNames[modalItem] : undefined} onClose={() => setModalVisibility(false)} onSubmit={handleSubmit}/>}
      </Container>
  )
}

export default InteractionsPanel;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  padding : 0.3rem;
  color : ${colors.text};
  border-bottom : 0.1rem solid ${colors.backgroundLight};
  cursor : pointer;

  &.selected {
    background-color: ${colors.backgroundSelect};
  }
`
