import styled from "styled-components";
import colors from "../contants/colors";
import { FormEvent, useEffect, useState } from "react";
import useAuthProvider from "../providers/authprovider";

const LoginPage : React.FC<{}> = () => {
  const {authProvider} = useAuthProvider();

  useEffect(() => {
    (async () => {
      const logged = await authProvider.init();
    })();
  }, [])

  return (
    <Container>
      <Frame>
        <button onClick={() => authProvider.login()}>Login</button>
      </Frame>
    </Container>
  )
}


export default LoginPage;

const Container = styled.div`
  position: fixed;
  top : 0;
  left : 0;
  width: 100%;
  height : 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.background};
`

const Frame = styled.div`
  border-radius: 1rem;
  background-color: #EEE;
  padding: 1rem;
`