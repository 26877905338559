const colors = {
  backgroundSelect : "#449",
  backgroundLight : "#444",
  backgroundMiddle : "#333",
  background : "#222",
  text : "#EEE",
  lightBackground : "#FFF",
  darkText : "#222",
  error : "#A00"
}

export default colors;