import { useState } from "react";
import Modal from "../components/modal"
import TextButton from "../components/text-button";
import TextInput from "../components/text-input";

interface NameModalProps {
  name ?: string;
  title : string;
  label : string;
  onClose ?: () => void;
  onSubmit ?: (name : string) => void;
}

const NameModal : React.FC<NameModalProps> = (p) => {
  const [name, setName] = useState<string | undefined>(p.name);

  const handleValidate = () => {
    if(name) {
      p.onSubmit?.(name)
    }
    p.onClose?.();
  }
  return (
    <Modal
      title={p.title}
      onClose={p.onClose}
      controls={
        <TextButton disabled={!name?.trim()} onClick={handleValidate}>Validate</TextButton>
      }
    >
      <TextInput
        label={p.label}
        value={name}
        onChange={setName}
      />
    </Modal>
  )
}

export default NameModal;


