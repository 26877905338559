import styled from "styled-components"
import TextSelect from "./text-select";
import { useModelStore } from "../../../store/store";
import { AssetRef, AssetType } from "../../../lib";
import TextButton from "./text-button";
import RemoveCrossIcon from "../../../assets/icons/remove-cross";
import { useState } from "react";

interface AssetInputProps {
  inline ?: boolean;
  value ?: AssetRef;
  onChange ?: (value : AssetRef) => void;
  onDelete ?: () => void;
  label ?: string;
  type ?: AssetType;
}

const AssetInput : React.FC<AssetInputProps> = (p) => {
  const model = useModelStore().model.getContent();
  const [selectRefresKey, setSelectRefreshKey] = useState<string>('---');

  const handleDelete = () => {
    p.onDelete?.();
    setSelectRefreshKey(`${Date.now()}`)
  }

  return (
    <Container className={p.inline ? 'inline' : ''}>
      <TextSelect
        key={selectRefresKey}
        label={p.label}
        options={model.assets.map((asset, index) => ({
          name : `[${asset.type}] ${asset.url.length < 15 ? asset.url : ('...' + asset.url.slice(-15))}`,
          value : `${index}`,
          type : asset.type
        })).filter(option => p.type === undefined || p.type === option.type)}
        value={p.value !== undefined ? `${p.value}` : undefined}
        onChange={(v) => p.onChange?.(parseInt(v))}
        />
      {
        p.onDelete ?
          <DeleteButtonContainer className={p.inline ? "inline" : ''}>
            <TextButton icon={<RemoveCrossIcon color="black"/>} onClick={handleDelete}>{p.inline ? undefined : 'Delete'}</TextButton>
          </DeleteButtonContainer> :
          null
      }
    </Container>
  )
}

export default AssetInput;

const Container = styled.div`
  margin-top: 1rem;

  &.inline {
    display: flex;
    margin-top : 0;
    margin-right : 0.5rem;
  }
`;


const DeleteButtonContainer = styled.div`
  margin-top : 1rem;

  &.inline {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`