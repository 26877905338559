import { ReactNode } from "react";
import styled from "styled-components";
import colors from "../../../contants/colors";

interface TextButtonProps {
  label ?: string;
  className ?: string;
  value ?: string;
  onChange ?: (value : string) => void;
  isError ?: boolean;
  inline ?: boolean;
}

const TextInput : React.FC<TextButtonProps> = (p) => {
  return (
    <Container className={p.inline ? 'inline' : ''}>
      {p.label && <Label>{p.label}</Label>}
      <Input onChange={e => p.onChange?.(e.target.value)} className={(p.className || '') + (p.isError ? ' error' : '')} value={p.value || ''}/>
    </Container>
  )
}

export default TextInput;

const Container = styled.div`
  margin-top : 1rem;

  &.inline {
    margin-top : 0;
    margin-right : 0.5rem;
  }
`

const Input = styled.input`
  background-color: ${colors.lightBackground};
  color : ${colors.darkText};
  border : 0.3rem solid transparent;
  border-radius: 0.5rem;
  padding : 0.5rem;
  outline: none;
  /* max-width : 30rem; */
  width : 100%;

  &.error {
    border-color : ${colors.error};
    box-shadow: inset 0px 0px 10px 0px rgba(255,0,0,0.41);
  }
`

const Label = styled.div`
  color : ${colors.text};
  font-size: 1rem;
  margin-bottom : 0.4rem
`
