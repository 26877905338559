import axios from "axios";
import configProvider from "./configprovider"
import useAuthProvider, { AuthProvider } from "./authprovider";

const url = configProvider('STATIC_API_URL').replace(/\/$/, '');
const space = configProvider('STATIC_SPACE') ? configProvider('STATIC_SPACE') + '/' : '';

export const nameFromKey = (key : string) => {
  return key.replace(/\/+$/g, '').split('/').pop() || '';
}

export const cleanUrlPath = (path : string) => {
  return path.replace(/\/+/g, '/');
}

class StorageProvider {
  private authProvider : AuthProvider;

  constructor(authProvider : AuthProvider) {
    this.authProvider = authProvider;
  }

  async listAll(prefix ?: string) : Promise<StorageFile[]> {
    const token = await this.authProvider.getToken();

    const directory = prefix ? prefix : '';
    const path = cleanUrlPath(`/list/${space}${directory}`);

    const response = await axios.get(`${url}${path}`, {headers : {Authorization : `Bearer: ${token}`}});
    return response.data.data;
  }

  async getOne(name : string) : Promise<string> {
    const token = await this.authProvider.getToken();

    const path = cleanUrlPath(`/${space}${name.replace(space, '')}`);

    const response = await axios.get(`${url}${path}`, {headers : {Authorization : `Bearer: ${token}`}});
    return response.data;
  }

  async exists(url : string) : Promise<boolean> {
    try {
      const token = await this.authProvider.getToken();

      const response = await axios.get(url, {headers : {Authorization : `Bearer: ${token}`}});
      return response.status >= 200 || response.status < 400;
    }
    catch(error) {
      return false;
    }
  }

  async store(name : string, content : string, onOverwrite ?: (name : string) => Promise<boolean>) : Promise<void> {
    const token = await this.authProvider.getToken();

    const form = new FormData();
    const fileName = nameFromKey(name);
    const file = new File([content], fileName);
    form.append('file', file);

    const path = '/' + space + cleanUrlPath(name.replace(space, '').split('/').slice(0, -1).join('/') + '/');
    const completePathUrl = `${url}${path}`;
    const completeUrl = completePathUrl + fileName;

    // If a callback to validate overwrite is provided, test if file exists
    if(onOverwrite) {
      if(await this.exists(completeUrl)) {
        if(!(await onOverwrite(name))) {
          return;
        }
      }
    }

    const response = await axios.put(completePathUrl, form, {headers : {Authorization : `Bearer: ${token}`}});
    return response.data;
  }
}

let storageProvider : StorageProvider | null = null;

const useStorageProvider = () => {
  const {authProvider} = useAuthProvider();

  if(!storageProvider) {
    storageProvider = new StorageProvider(authProvider);
  }

  return storageProvider;
}

export default useStorageProvider;

export type StorageFile = {
  "id": string;
  "key": string;
  "lastModified": string;
  "size": number;
  "directory": boolean;
}
