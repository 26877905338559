
import colors from "../../contants/colors";
import IconProps from "./icon-interface";

const UnarchiveIcon : React.FC<IconProps & {onClick?:()=>void}> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg onClick={p.onClick} className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.06166 15.7004C6.9456 15.7004 4.82954 15.7004 2.71348 15.7004C1.588 15.7004 0.713051 15.0497 0.504913 14.0684C0.474078 13.9203 0.474078 13.7688 0.474078 13.6173C0.474078 10.9007 0.474078 8.18416 0.46637 5.47101C0.46637 5.17835 0.531894 4.92012 0.678361 4.66188C1.34902 3.49124 2.00042 2.3137 2.65566 1.13617C2.94474 0.61282 3.39956 0.358032 4.05866 0.358032C7.39271 0.361475 10.7268 0.361475 14.0569 0.358032C14.716 0.358032 15.167 0.61282 15.4599 1.13617C16.1614 2.39634 16.8745 3.6565 17.576 4.91667C17.6223 5.00275 17.6531 5.10948 17.6531 5.20589C17.6531 8.03266 17.6531 10.8594 17.6454 13.6862C17.6454 14.6434 17.0056 15.4043 15.9996 15.635C15.7837 15.6832 15.5563 15.697 15.3328 15.697C13.2398 15.7004 11.1507 15.697 9.0578 15.697L9.06166 15.7004ZM16.4081 5.75334C16.3696 5.72235 16.3195 5.72924 16.3118 5.72924C7.93232 5.72235 1.93104 5.71547 1.81541 5.70858C1.80384 5.70858 1.73832 5.70169 1.69977 5.73957C1.67665 5.76367 1.66894 5.79466 1.67279 5.83597C1.6381 9.96767 1.64581 12.9873 1.67279 13.6655C1.67665 13.8067 1.69592 14.1372 1.95416 14.3714C2.13917 14.5401 2.41284 14.6296 2.75588 14.6296C6.95716 14.6296 11.1584 14.6296 15.3597 14.6296C15.4715 14.6296 15.5871 14.6262 15.6951 14.6021C15.9032 14.5573 16.0805 14.4575 16.2077 14.3266C16.4004 14.1269 16.4351 13.879 16.4428 13.7344C16.4775 13.2283 16.4852 10.1123 16.4428 5.83942C16.4428 5.83253 16.4428 5.78777 16.4043 5.75678L16.4081 5.75334ZM16.0265 4.59991C16.0574 4.56203 15.9919 4.47596 15.9803 4.45874C15.8145 4.2315 14.5118 1.82823 14.4038 1.63542C14.319 1.48392 14.2188 1.42883 14.0261 1.42883C10.7113 1.43227 7.39656 1.43227 4.08564 1.42883C3.99314 1.42883 3.92376 1.4426 3.86209 1.47359C3.77344 1.52524 3.73489 1.60098 3.72333 1.62509C3.63082 1.80413 2.28564 4.26593 2.13917 4.46563C2.12761 4.4794 2.0775 4.54138 2.10449 4.58269C2.10449 4.58269 2.12376 4.61368 2.19314 4.62057C2.33189 4.63434 10.0677 4.62745 15.961 4.62057C15.9841 4.62057 16.0188 4.62057 16.0304 4.59991H16.0265Z" fill={color}/>
      <path d="M9.6543 9.33554C9.6543 9.33554 9.6543 9.38374 9.6543 9.38719C9.66586 9.44572 9.66972 10.2893 9.6543 12.2518C9.6543 12.2725 9.66201 12.6065 9.38449 12.7683C9.24574 12.8509 9.09927 12.8544 9.06073 12.8544C8.70612 12.8544 8.45944 12.6134 8.45944 12.2587C8.45944 11.4048 8.45944 10.5509 8.45944 9.70051V9.33898C8.38621 9.39752 8.34381 9.43195 8.30141 9.46982C7.90826 9.82102 7.51897 10.1722 7.12197 10.5234C6.86372 10.7541 6.4937 10.7575 6.25088 10.5406C6.01576 10.3306 6.01961 10.0001 6.26629 9.77626C7.05259 9.06698 7.84274 8.36115 8.63289 7.65532C8.87957 7.43496 9.23803 7.4384 9.48471 7.6622C10.271 8.36459 11.0573 9.06698 11.8397 9.76937C12.098 10.0001 12.1018 10.3306 11.8552 10.5475C11.6123 10.761 11.25 10.7506 10.9841 10.52C10.5948 10.1757 10.2055 9.8279 9.81619 9.48015C9.78535 9.45261 9.74681 9.43195 9.6967 9.37686C9.68128 9.35964 9.66201 9.33554 9.65045 9.33898L9.6543 9.33554Z" fill={color}/>
    </svg>
  )
}

export default UnarchiveIcon;

