import styled from "styled-components";
import List from "../components/list";
import { useModelStore } from "../../../store/store";
import { useModelActions } from "../../../store/hooks/use-actions";
import AssetModal from "../modals/asset-modal";
import { useState } from "react";
import { Asset } from "../../../lib";

interface AssetsPanelProps {

}

const AssetsPanel : React.FC<AssetsPanelProps> = (p) => {
  const model = useModelStore(state => state.model);
  const {addAsset, updateAsset, removeAsset} = useModelActions('assets');
  
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const [modalItem, setModalItem] = useState<number | undefined>();
  
  const {selectItem} = useModelActions('editor');
  const itemSelection = useModelStore(state => state.itemSelection);

  const handleAddAsset = () => {
    setModalItem(undefined);
    setModalVisibility(true);
  }

  const handleEdit = (index : number) => {
    setModalItem(index);
    setModalVisibility(true);
  }

  const handleSubmit = (asset : Asset) => {
    if(typeof modalItem === 'number') {
      updateAsset(modalItem, asset);
    }
    else {
      addAsset(asset)
    }
  }

  const handleRemove = (index : number) => {
    if(window.confirm(`Confirmer la suppression de l'asset`)) {
      removeAsset(index);
    }
  }

  const handleSelect = (index : number) => {
    selectItem({type : 'asset', index})
  }

  const isSelected = (index : number) => {
    return !!itemSelection && itemSelection.type === 'asset' && itemSelection.index === index
  }

  return (
    <Container>
      <List
        items={model.assets().map(asset => `[${asset.type}] ${asset.url}`)}
        onAdd={handleAddAsset}
        onEdit={handleEdit}
        onDelete={handleRemove}
        onSelect={handleSelect}
        isSelected={isSelected}
      />
      {modalVisibility && <AssetModal asset={modalItem !== undefined ? model.assets()[modalItem] : undefined} onClose={() => setModalVisibility(false)} onSubmit={handleSubmit}/>}
    </Container>
  )
}

export default AssetsPanel;

const Container = styled.div`
`