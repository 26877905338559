import { useState } from "react";
import { Asset, AssetType } from "../../../lib";
import Modal from "../components/modal"
import TextSelect from "../components/text-select";
import TextButton from "../components/text-button";
import TextInput from "../components/text-input";
import styled from "styled-components";
import CloudDownloadIcon from "../../../assets/icons/cloud-download";
import OpenCloudFileModal from "./open-cloud-file-modal";
import configProvider from "../../../providers/configprovider";

const STATIC_API_URL = configProvider('STATIC_API_URL');

interface AssetModalProps {
  asset ?: Asset;
  onClose ?: () => void;
  onSubmit ?: (asset : Asset) => void;
}

const AssetModal : React.FC<AssetModalProps> = (p) => {
  const [type, setType] = useState<AssetType | undefined>(p.asset?.type)
  const [url, setUrl] = useState<string | undefined>(p.asset?.url);
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleValidate = () => {
    if(type && url) {
      p.onSubmit?.({
        type, url
      })
    }
    p.onClose?.();
  }
  return (
    <Modal
      title={"Edition des assets"}
      onClose={p.onClose}
      controls={
        <TextButton disabled={!type || !url} onClick={handleValidate}>Validate</TextButton>
      }
    >
      <TextSelect
        label="Type de asset"
        options={[
          {name : "3D", value : "3D"},
          {name : "2D", value : "2D"},
          {name : "Video", value : "video"},
          {name : "Skybox", value : "Skybox"}
        ]}
        value={type}
        onChange={(v) => setType(v as AssetType)}
      />
      <UrlContainer>
        <TextInput
          label="Url de l'asset"
          value={url}
          onChange={setUrl}
          inline
        />
        <Button onClick={() => setShowModal(true)}><CloudDownloadIcon /></Button>
      </UrlContainer>
      {showModal ? <OpenCloudFileModal onSubmit={value => setUrl(STATIC_API_URL + '/' + value)} onClose={() => setShowModal(false)}/> : null}
    </Modal>
  )
}

export default AssetModal

const UrlContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

const Button = styled.div`
  margin-top: 1.5rem;
  width : 3rem;
  height : 3rem;
  padding : 0.5rem;
`

