import { useEffect, useState } from "react";
import { Immutable } from 'immer';
import Ugla3DStateManager from "./ugla-3d-state-manager"
import { CameraStateEvent, ControlStateEvent, InteractionsStateEvent, LightsStateEvent, ModsStateEvent, SceneStateEvent, StateChangeEvent, StateResetEvent, Ugla3DState, VisibilityStateEvent } from "./ugla-3d-state-type";
import { Asset, ControlType, Interaction, Light, Modification, SceneObject, Vec3, Vec4 } from "../model/ugla-filetype";
import { Ugla3D } from "../model/ugla-3d";

type UseStateReturType<T> = [T, (value : T) => void];

export const stateManager = new Ugla3DStateManager();

export const useUgla3dStateManager = () => {
  return stateManager;
}

export const useUgla3dModel = () => {
  const [model, setModel] = useState<Immutable<Ugla3D>>(stateManager.getModel());

  useEffect(() => {
    const callback = (event : StateResetEvent) => {
      setModel(stateManager.getModel());
    }
    stateManager.on('reset', callback);

    return () => {
      stateManager.off('reset', callback);
    }
  }, []);

  return model;
}


export const useUgla3dState = () => {
  const [state, setState] = useState<Immutable<Ugla3DState>>(stateManager.getState());

  useEffect(() => {
    const callback = (event : StateChangeEvent) => {
      setState(event.value);
    }
    stateManager.on('change', callback);

    return () => {
      stateManager.off('change', callback);
    }
  }, []);

  return state;
}

export const useControlState = () : UseStateReturType<ControlType> => {
  const [control, setControl] = useState<ControlType>(stateManager.getState().control);

  useEffect(() => {
    const callback = (event : ControlStateEvent) => {
      setControl(event.value);
    }
    stateManager.on('control', callback);

    return () => {
      stateManager.off('control', callback);
    }
  }, []);

  return [
    control,
    (control : ControlType) => {
      stateManager.setControl(control);
    }
  ];
}

export const useCameraState = () : UseStateReturType<Immutable<Ugla3DState['camera']>> => {
  const [camera, setCamera] = useState<Immutable<Ugla3DState>['camera']>(stateManager.getState().camera);

  useEffect(() => {
    const callback = (event : CameraStateEvent) => {
      setCamera(event.value);
    }
    stateManager.on('camera', callback);

    return () => {
      stateManager.off('camera', callback);
    }
  }, []);

  return [
    camera,
    (camera : Immutable<Ugla3DState['camera']>) => {
      stateManager.setCamera(camera.position, camera.rotation, camera.fov);
    }
  ];
}

export const useCameraPositionState = () : UseStateReturType<Immutable<Vec3>> => {
  const [cameraPosition, setCameraPosition] = useState<Immutable<Vec3>>(stateManager.getState().camera.position);

  useEffect(() => {
    const callback = (event : CameraStateEvent) => {
      setCameraPosition(event.value.position);
    }
    stateManager.on("camera", callback);

    return () => {
      stateManager.off('camera', callback);
    }
  }, []);

  return [
    cameraPosition,
    (position : Immutable<Vec3>) => {
      stateManager.setCameraPosition(position);
    }
  ];
}

export const useCameraRotationState = () : UseStateReturType<Immutable<Vec4>> => {
  const [cameraRotation, setCameraRotation] = useState<Immutable<Vec4>>(stateManager.getState().camera.rotation);

  useEffect(() => {
    const callback = (event : CameraStateEvent) => {
      setCameraRotation(event.value.rotation);
    }
    stateManager.on("camera", callback);

    return () => {
      stateManager.off('camera', callback);
    }
  }, []);

  return [
    cameraRotation,
    (rotation : Immutable<Vec4>) => {
      stateManager.setCameraRotation(rotation);
    }
  ];
}

export const useCameraFovState = () : UseStateReturType<number> => {
  const [cameraFov, setCameraFov] = useState<number>(stateManager.getState().camera.fov);

  useEffect(() => {
    const callback = (event : CameraStateEvent) => {
      setCameraFov(event.value.fov);
    }
    stateManager.on("camera", callback);

    return () => {
      stateManager.off('camera', callback);
    }
  }, []);

  return [
    cameraFov,
    (fov : number) => {
      stateManager.setCameraFov(fov);
    }
  ];
}

export const useDefaultCamera = () => {
  const [camera, setCamera] = useState<Ugla3DState['camera']>(stateManager.getDefaultCamera());

  useEffect(() => {
    const callback = () => {
      setCamera(stateManager.getDefaultCamera());
    }
    stateManager.on('reset', callback);

    return () => {
      stateManager.off('reset', callback);
    }
  }, []);

  return camera;
}

export const useSceneState = () => {
  const [scene, setScene] = useState<Immutable<SceneObject<Asset>[]>>(stateManager.getState().scene);

  useEffect(() => {
    const callback = (event : SceneStateEvent) => {
      setScene(event.value);
    }
    stateManager.on("scene", callback);

    return () => {
      stateManager.off('scene', callback);
    }
  }, []);


  return scene;
}

export const useInteractionsState = () => {
  const [interactions, setInteractions] = useState<Immutable<Interaction<Asset>[]>>(stateManager.getState().interactions);

  useEffect(() => {
    const callback = (event : InteractionsStateEvent) => {
      setInteractions(event.value);
    }
    stateManager.on("interactions", callback);

    return () => {
      stateManager.off('interactions', callback);
    }
  }, []);


  return interactions;
}

export const useVisibilityState = () => {
  const [visibility, setVisibility] = useState<Immutable<Ugla3DState['changes']['visibility']>>(stateManager.getState().changes.visibility);

  useEffect(() => {
    const callback = (event : VisibilityStateEvent) => {
      setVisibility(event.value);
    }
    stateManager.on("visibility", callback);

    return () => {
      stateManager.off('visibility', callback);
    }
  }, []);

  return visibility;
}

export const useModsState = () => {
  const [mods, setMods] = useState<Immutable<Modification<Asset>[]>>(stateManager.getMods());

  useEffect(() => {
    const callback = (event : ModsStateEvent) => {
      setMods(event.value);
    }
    stateManager.on("mods", callback);

    return () => {
      stateManager.off('mods', callback);
    }
  }, []);


  return mods;
}

export const useLightsState = () => {
  const [lights, setLights] = useState<Immutable<Ugla3DState['lights']>>(stateManager.getState().lights);

  useEffect(() => {
    const callback = (event : LightsStateEvent) => {
      setLights(event.value);
    }
    stateManager.on("lights", callback);

    return () => {
      stateManager.off('lights', callback);
    }
  }, []);

  return lights;
}