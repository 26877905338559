import { ChangeEvent, ReactNode, useEffect, useState } from "react"
import { useResizeDetector } from 'react-resize-detector';
import styled from "styled-components"
import colors from "../../../contants/colors";

type ViewTypes = 'responsive' | 'ir' | '16_9_landscape' | '16_9_portrait' | 'custom';

const SIZES : {[type in ViewTypes] : [number, number]}= {
  'responsive' : [1000, 1000],
  'ir' : [5760, 1080],
  '16_9_landscape' : [1920, 1080],
  '16_9_portrait' : [1080, 1920],
  'custom' : [1920, 1080]
}

interface ResponsiveProps {
  children : ReactNode;
  onScaleChange ?: (scale : number) => void;
}

const Responsive : React.FC<ResponsiveProps> = (p) => {
  const [type, setType] = useState<ViewTypes>('responsive');
  const [customWidth, setCustomWidth] = useState<number>(1920);
  const [customHeight, setCustomHeight] = useState<number>(1080);
  const { width = 1, height = 1, ref } = useResizeDetector();
  const [scale, setScale] = useState<number>(1);

  const handleSelectorChange = (e : ChangeEvent<HTMLSelectElement>) => {
    setType(e.target.value as ViewTypes);
  }

  const targetWidth = type === 'responsive' ? width : type === 'custom' ? customWidth : SIZES[type][0];
  const targetHeight = type === 'responsive' ? height : type === 'custom' ? customHeight : SIZES[type][1];

  useEffect(() => {
    const scale = Math.min(1, width / targetWidth, height / targetHeight);
    setScale(scale);
    p.onScaleChange?.(scale);
  }, [width, height, targetWidth, targetHeight]);

  return (
    <Container ref={ref}>
      <Viewport style={{width : targetWidth * scale, height : targetHeight * scale}}>
        {p.children}
      </Viewport>
      <Selector>
        <select onChange={handleSelectorChange} value={type}>
          <option value="responsive">Responsive</option>
          <option value="ir">Immersive Room</option>
          <option value="16_9_landscape">16:9 Landscape</option>
          <option value="16_9_portrait">16:9 Portrait</option>
          <option value="custom">Custom</option>
        </select>
        {
          type === 'custom' ?
            <CustomSize>
              <input value={customWidth} onChange={(e : ChangeEvent<HTMLInputElement>) => setCustomWidth(parseInt(e.target.value))} />
              &nbsp;x&nbsp;
              <input value={customHeight} onChange={(e : ChangeEvent<HTMLInputElement>) => setCustomHeight(parseInt(e.target.value))} />
            </CustomSize> :
            null
        }
      </Selector>
    </Container>
  )
}

export default Responsive;

const Container = styled.div`
  position : relative;
  width : 100%;
  height : 100%;
  display: flex;
  justify-content : center;
  align-items: center;
  overflow: hidden;
  background-color: ${colors.backgroundMiddle};
`

const Viewport = styled.div`
  position : absolute;
  border : 1px solid white;
`

const Selector = styled.div`
  position: absolute;
  top : 0;
  left : 50%;
  transform : translateX(-50%);
  display: flex;


  & > select {
    background-color: ${colors.background};
    color: ${colors.text};
    border : none;
    outline : none;
    padding : 1px 10px;
  }
`

const CustomSize = styled.div`
  padding-left : 10px;
  background-color: ${colors.background};
  color: ${colors.text};

  & > input {
    width : 50px;
    background-color: ${colors.background};
    color: ${colors.text};
    border : 1px solid ${colors.backgroundLight};
    outline : none;
    padding : 1px 5px;
  }
  
`