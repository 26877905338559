import styled from "styled-components";
import colors from "../../../contants/colors";
import { useEffect, useRef, useState } from "react";
import transitions from "../../../contants/transitions";

export type Node<T extends any> = {
  label : string;
  value : T;
  children ?: Node<T>[];
}

interface TreeProps<T extends any> {
  tree : Node<T>
  onSelect ?: (value : T) => void;
  isSelected ?: (value : T) => boolean;
}

const Tree = <T extends any>(p : TreeProps<T>) => {
  const [open, setOpen] = useState<boolean>(true);
  const labelRef = useRef<HTMLDivElement>(null);

  const selected = !!p.isSelected?.(p.tree.value);

  useEffect(() => {
    if(selected && labelRef) {
      labelRef.current?.scrollIntoView();
    }
  }, [selected])

  const handleSelect = (e : React.MouseEvent) => {
    e.stopPropagation();
    p.onSelect?.(p.tree.value);
  }

  const handleToggle = () => {
    if(p.tree.children?.length) {
      setOpen(o => !o)
    }
  }

  return (
    <Container>
      <Item onClick={handleToggle} className={selected ? 'selected' : ''}>
        <Chevron className={open ? 'visible' : ''}>{p.tree.children?.length ? '▶' : '-'}</Chevron>
        <Label ref={labelRef} onClick={handleSelect}>
          {p.tree.label}
        </Label>
      </Item>
      <Children className={open ? 'visible' : ''}>
        {
          (p.tree.children || []).map((node, index) => (
            <Tree<T> key={node.label + `${index}`} tree={node} onSelect={p.onSelect} isSelected={p.isSelected}/>
          ))
        }
      </Children>
    </Container>
  )
}

export default Tree;

const Container = styled.div`
`

const Item = styled.div`
  padding : 0.3rem;
  color : ${colors.text};
  font-size : 1rem;
  display: flex;
  cursor : pointer;

  &.selected {
    background-color: ${colors.backgroundSelect};
  }
`

const Chevron = styled.div`
  transition: ${transitions.fast};
  margin-right : 1rem;

  &.visible {
    transform: rotate(-90deg);
  }
`

const Label = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Children = styled.div`
  padding-left : 1.5rem;
  display: none;

  &.visible {
    display: block;
  }
`
