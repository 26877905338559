import { ObjectPath } from "../model/ugla-filetype";
import axios from "axios";
import { Immutable } from 'immer';
import Ugla3DStateManager from "../model-state/ugla-3d-state-manager";
import { CameraStateEvent, ModsStateEvent, SceneStateEvent, StateResetEvent, Ugla3DState, VisibilityStateEvent } from "../model-state/ugla-3d-state-type";
import { objectPathToString } from "../model/ugla-types-tools";

interface UnityViewerBridgeOptions {
  viewerUrl : string;
  appName   : string;
  mode      : '2d' | '3d';
  assetsPrefix ?: string;
}

type HTTPMethod = 'put' | 'post' | 'patch' | 'delete';

type QueuedRequest = {
  method  : HTTPMethod;
  url     : string;
  payload ?: any;
}

export class UnityViewerBridge {
  private stateManager : Ugla3DStateManager;
  private options : UnityViewerBridgeOptions;
  private isRunning : boolean = false;
  private queue : QueuedRequest[] = [];
  private pending : boolean = false;

  constructor(stateManager : Ugla3DStateManager, options : UnityViewerBridgeOptions) {
    this.stateManager = stateManager;
    this.options      = options;

    this.onReset = this.onReset.bind(this);
    this.onCamera = this.onCamera.bind(this);
    this.onScene = this.onScene.bind(this);
    this.onMods = this.onMods.bind(this);
    this.onVisibility = this.onVisibility.bind(this);

    this.stateManager.on('reset', this.onReset);
    this.stateManager.on('camera', this.onCamera);
    this.stateManager.on('scene', this.onScene);
    this.stateManager.on('mods', this.onMods);
    this.stateManager.on('visibility', this.onVisibility);
  }

  start() {
    this.isRunning = true;
  }

  stop() {
    this.isRunning = false;
  }

  get running() {
    return this.isRunning;
  }

  get url() {
    return this.options.viewerUrl
  }

  set url(url : string) {
    this.options.viewerUrl = url;
  }

  private addToQueue(request : QueuedRequest) {
    this.queue.push(request);
    this.processQueue();
  }

  private async processQueue() {
    if(!this.pending) {
      this.pending = true;

      const request = this.queue.shift();

      if(request) {
        try {
          await axios({
            url : request.url,
            method : request.method,
            data : request.payload
          })
        }
        catch (error) {
          console.error(error);
        }
      }

      this.pending = false;

      if(this.queue.length) {
        // await new Promise((resolve, reject) => setTimeout(resolve, 20));
        await this.processQueue();
      }
    }
  }

  private onReset(event : StateResetEvent) {
    if(this.running) {
      this.addToQueue({
        url : `${this.options.viewerUrl}/scenes`,
        method : 'post',
        payload : {
          type : this.options.mode,
          id : this.options.appName
        }
      })
    }
  }

  private onCamera(event : CameraStateEvent) {
    if(this.running) {
      try {
        axios({
          url : `${this.options.viewerUrl}/scenes/${this.options.appName}/camera`,
          method : 'put',
          data : {
            position :{
              x : -event.value.position[0],
              y : event.value.position[1],
              z : event.value.position[2]
            },
            rotation : {
              x : event.value.rotation[0], // / Math.PI * 180,
              y : -event.value.rotation[1], // / Math.PI * 180,
              z : event.value.rotation[2], // / Math.PI * 180,
              w : event.value.rotation[3]
            }
          }
        })
      }
      catch(error) {

      }
    }
  }

  private onScene(event : SceneStateEvent) {
    if(this.running) {
      const objects : any = [];

      event.added.forEach(obj => {
        if(obj.asset.type === '3D') {
          objects.push({
            "id" : obj.id,
            "type": "3d",
            "url": (this.options.assetsPrefix || '') + obj.asset.url,
            "position": {
                "x": 0,
                "y": 0,
                "z": 0
            },
            "scale": {
                "x": 1,
                "y": 1,
                "z": 1
            },
            "rotation": {
                "x": 0,
                "y": 0,
                "z": 0
            }
          })
        }
        if(obj.asset.type === '2D') {
          objects.push({
            "id" : obj.id,
            "type": "image",
            "layer": 0,
            "url": (this.options.assetsPrefix || '') + obj.asset.url,
            "position": {
                "x": 0,
                "y": 0,
            },
            "resolution" : {
              "x": 0,
              "y": 0
            }
          })
        }
      })

      this.addToQueue({
        url : `${this.options.viewerUrl}/scenes/${this.options.appName}/objects`,
        method : 'post',
        payload: {objects}
      })

      event.removed.forEach(obj => {
        this.addToQueue({
          url : `${this.options.viewerUrl}/scenes/${this.options.appName}/objects/${obj.id}`,
          method : 'delete'
        })
      })
    }
  }

  private onMods(event : ModsStateEvent) {
    if(this.running) {
      const addedMods = event.value.map(mod => objectPathToString({objectId : mod.path.objectId, path : [...mod.path.path]}));

      (event.removed || [])
        .filter(mod => !addedMods.includes(objectPathToString({objectId : mod.path.objectId, path : [...mod.path.path]})))
        .forEach(mod => {
          if(mod.type === '3D') {
            this.addToQueue({
              url : `${this.options.viewerUrl}/scenes/${this.options.appName}/objects/${mod.path.objectId}/mods/${mod.path.path.join(',')}`,
              method : 'delete',
            })
          }
          if(mod.type === '2D') {
            this.addToQueue({
              url : `${this.options.viewerUrl}/scenes/${this.options.appName}/objects/${mod.path.objectId}/mods`,
              method : 'delete',
            })
          }
        })

      event.value.forEach(mod => {
        if(mod.type === '3D') {
          this.addToQueue({
            url : `${this.options.viewerUrl}/scenes/${this.options.appName}/objects/${mod.path.objectId}/mods/${mod.path.path.join(',')}`,
            method : 'put',
            payload : {
              ...(mod.position ? {"position": {
                "x": -mod.position[0],
                "y": mod.position[1],
                "z": mod.position[2]
              }} : {}),
              ...(mod.scale ? {"scale": {
                  "x": mod.scale[0],
                  "y": mod.scale[1],
                  "z": mod.scale[2]
              }} : {}),
              ...(mod.rotation ? {"rotation": {
                  "x": mod.rotation[0],
                  "y": -mod.rotation[1],
                  "z": -mod.rotation[2]
              }} : {}),
              ...(mod.material ? {
                "material" : {
                  materialName : mod.materialName,
                  baseColor    : mod.material.baseColor,
                  diffuse      : mod.material.diffuse?.url,
                  roughness    : mod.material.roughness?.url,
                  normalMap    : mod.material.normalMap?.url,
                  tiling       : mod.material.tiling && {x : mod.material.tiling[0], y : mod.material.tiling[1]}
                }
              } : {})
            }
          })
        }
        if(mod.type === '2D') {
          this.addToQueue({
            url : `${this.options.viewerUrl}/scenes/${this.options.appName}/objects/${mod.path.objectId}/mods`,
            method : 'put',
            payload : {
              ...(mod.position ? {
                position : {
                  x : mod.position[0],
                  y : -mod.position[1]
                }
              } : {}),
              ...(mod.zIndex !== undefined ? {layer : mod.zIndex} : {}),
              ...(mod.scale ? {
                resolution : {
                  x : mod.scale[0],
                  y : mod.scale[1]
                }
              } : {})
            }
          })
        }
      });
    }
  }

  private onVisibility(event : VisibilityStateEvent) {
    const newValuesPaths = [...(event.value.hide || []), ...(event.value.show || [])].map(obj =>
      objectPathToString({objectId : obj.objectId, path : [...obj.path]})
    );

    const show : Immutable<ObjectPath>[] = [
      ...event.value.show,
      ...event.removed.hide
        .filter(obj => !newValuesPaths.includes(objectPathToString({objectId : obj.objectId, path : [...obj.path]})))
    ];
    const hide : Immutable<ObjectPath>[] = [
      ...event.value.hide,
      ...event.removed.show
        .filter(obj => !newValuesPaths.includes(objectPathToString({objectId : obj.objectId, path : [...obj.path]})))
    ];

    hide.forEach(obj => {
      this.addToQueue({
        url : `${this.options.viewerUrl}/scenes/${this.options.appName}/objects/${obj.objectId}/visibility/${obj.path.join(',')}`,
        method : 'put',
        payload : {
          visibility : false
        }
      });
    })
    show.forEach(obj => {
      this.addToQueue({
        url : `${this.options.viewerUrl}/scenes/${this.options.appName}/objects/${obj.objectId}/visibility/${obj.path.join(',')}`,
        method : 'put',
        payload : {
          visibility : true
        }
      });
    })
  }
}

