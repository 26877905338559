import { assert } from "../assert-type";
import { Asset, Control, Interaction, SceneObject, UglaFile, Vec3, Animation, isAnimation, isAsset, isCamera, isControl, isInteraction, isSceneObject, Vec4, isModification, Modification, isLight, Light } from "./ugla-filetype";
import {cloneDeep} from 'lodash';

export class Ugla3D {
  private content : UglaFile;

  constructor(model ?: string | Ugla3D) {
    if(model instanceof Ugla3D) {
      this.content = cloneDeep(model.content);
    }
    else if(typeof model === 'string') {
      this.content = this.importJSON(model);
    }
    else {
      this.content = {
        assets : [],
        scene : [],
        interactions : {}
      }
    }
  }

  private importJSON (json: string) {
    const parsed = JSON.parse(json);
    const content : UglaFile = {
      assets : [],
      scene : [],
      interactions : {}
    }

    const {assets, scene, camera, defaultControls, defaultInteractionsGroups, interactions, animations, mods, lights} = parsed;

    const log : string[] = [];
    if(!(
      assert(Array.isArray(assets) && assets.every(a => isAsset(a, log)), '-> assets', log) &&
      assert(Array.isArray(scene) && scene.every(o => isSceneObject(o, log)), '-> scene', log) &&
      assert((camera === undefined || isCamera(camera, log)), '-> camera', log) &&
      assert((defaultControls === undefined || isControl(defaultControls, log)), '-> defaultControls', log) &&
      assert(Object.values(interactions || {}).every(i => Array.isArray(i) && i.every(ii => isInteraction(ii, log))), '-> interactions', log) &&
      assert(defaultInteractionsGroups === undefined || (Array.isArray(defaultInteractionsGroups) && defaultInteractionsGroups.every(a => typeof a === 'string' && !!interactions[a])), '-> defaultInteractions', log) &&
      assert(Object.values(mods || {}).every(m => Array.isArray(m) && m.every(mm => isModification(mm))), '-> mods', log) &&
      assert(Object.values(animations || {}).every(a => isAnimation(a)), '-> animations', log) &&
      assert((lights || []).every((l : any) => isLight(l)), '-> lights', log)
      )) {
      console.error('Invalid format:' + log.reverse().join('\n'))
      throw 'Invalid format:' + log.join('\n');
    }

    content.assets = assets.filter((a : any) : a is Asset => isAsset(a, []));
    content.scene = scene.filter((o : any) => isSceneObject(o));
    content.camera = camera;
    content.defaultControls = defaultControls;
    content.defaultInteractionsGroups = defaultInteractionsGroups;
    content.interactions = interactions;
    content.animations = animations;
    content.mods = mods;
    content.lights = lights;

    return content;
  }

  getContent() {
    return this.content;
  }

  assets() {
    return this.content.assets;
  }

  addAsset(asset : Asset) {
    const clone = new Ugla3D(this);
    clone.content.assets.push(asset);
    return clone;
  }

  updateAsset(index : number, asset : Partial<Asset>) {
    const clone = new Ugla3D(this);
    clone.content.assets[index] = {
      ...clone.content.assets[index],
      ...asset
    };
    return clone;
  }

  removeAsset(index : number) {
    const clone = new Ugla3D(this);
    clone.content.assets.splice(index, 1);
    return clone;
  }

  objects() {
    return this.content.scene;
  }

  addObject(obj : SceneObject) {
    const clone = new Ugla3D(this);
    clone.content.scene.push(obj);
    return clone;
  }

  updateObject(_indexOrId : number | string, obj : Partial<SceneObject>) {
    const clone = new Ugla3D(this);
    const index : number = typeof _indexOrId === 'number' ? _indexOrId : clone.content.scene.findIndex(i => i.id === _indexOrId);

    clone.content.scene[index] = {
      ...clone.content.scene[index],
      ...obj
    };

    return clone;
  }

  removeObject(_indexOrId : number | string) {
    const clone = new Ugla3D(this);
    const index : number = typeof _indexOrId === 'number' ? _indexOrId : clone.content.scene.findIndex(i => i.id === _indexOrId);

    clone.content.scene.splice(index, 1);
    return clone;
  }

  clearObjects() {
    const clone = new Ugla3D(this);
    clone.content.scene = [];
    return clone;
  }

  camera() {
    return this.content.camera;
  }

  setCameraDefaultPosition(value : Vec3) {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    clone.content.camera.defaultPosition = value;
    return clone;
  }

  clearCameraDefaultPosition() {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    delete clone.content.camera.defaultPosition;
    return clone;
  }

  setCameraDefaultRotation(value : Vec4) {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    clone.content.camera.defaultRotation = value;
    return clone;
  }

  clearCameraDefaultRotation() {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    delete clone.content.camera.defaultRotation;
    return clone;
  }

  setCameraDefaultFov(value : number) {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    clone.content.camera.defaultFov = value;
    return clone;
  }

  clearCameraDefaultFov() {
    const clone = new Ugla3D(this);
    if(!clone.content.camera) {
      clone.content.camera = {};
    }
    delete clone.content.camera.defaultFov;
    return clone;
  }

  defaultControls () {
    return this.content.defaultControls;
  }

  setDefaultControls(controls : Control) {
    const clone = new Ugla3D(this);
    clone.content.defaultControls = controls;
    return clone;
  }

  clearDefaultControls() {
    const clone = new Ugla3D(this);
    delete clone.content.defaultControls;
    return clone;
  }

  defaultInteractionsGroup() {
    return this.content.defaultInteractionsGroups
  }

  interactions(_group ?: string | string[]) {
    const groups = Array.isArray(_group) ? _group : _group ? [_group] : this.content.defaultInteractionsGroups || Object.keys(this.content.interactions);
    let interactions : Interaction[] = [];

    for(let group of groups) {
      interactions = [...interactions, ...(this.content.interactions[group] || [])];
    }
    return interactions;
  }

  interactionsGroupsNames() {
    return Object.keys(this.content.interactions);
  }

  setDefaultInteractionsGroups(names : string | string[]) {
    const clone = new Ugla3D(this);

    clone.content.defaultInteractionsGroups = Array.isArray(names) ? names : [names];
    return clone;
  }

  clearDefaultInteractionsGroup() {
    const clone = new Ugla3D(this);
    delete clone.content.defaultInteractionsGroups;
    return clone;
  }

  createInteractionGroup(group : string) {
    const clone = new Ugla3D(this);

    if(!clone.content.interactions[group]) {
      clone.content.interactions[group] = [];
    }

    return clone;
  }

  deleteInteractionGroup(group : string) {
    const clone = new Ugla3D(this);

    delete clone.content.interactions[group];
    if(clone.content.defaultInteractionsGroups) {
      clone.content.defaultInteractionsGroups = clone.content.defaultInteractionsGroups.filter(ig => ig !== group)
    }

    return clone;
  }

  updateInteractionGroup(previous : string, next : string) {
    const clone = new Ugla3D(this);

    if(clone.content.interactions[next]) {
      // Do not change the name of an interaction group if the target name already exist
      return this;
    }

    clone.content.interactions[next] = clone.content.interactions[previous];
    delete clone.content.interactions[previous];

    if(clone.content.defaultInteractionsGroups?.find(ig => ig === previous)) {
      clone.content.defaultInteractionsGroups = clone.content.defaultInteractionsGroups.map(ig => ig === previous ? next : ig);
    }

    return clone;
  }

  setInteraction(group : string, interaction : Interaction) {
    const clone = new Ugla3D(this);

    if(!clone.content.interactions[group]) {
      clone.content.interactions[group] = [];
    }

    clone.content.interactions[group] = [...clone.content.interactions[group].filter(i => i.id != interaction.id), interaction];

    return clone;
  }

  updateInteraction(group :string, id : string, interaction : Interaction) {
    const clone = new Ugla3D(this);

    if(!clone.content.interactions[group]) {
      clone.content.interactions[group] = [];
    }

    if(interaction.id !== id && clone.content.interactions[group].find(i => i.id === interaction.id)) {
      //Changing the id of an interaction with an id that is already in the array, this will cause problems.
      //Do nothing

      return this;
    }

    clone.content.interactions[group] = clone.content.interactions[group].map(i => i.id != id ? i : interaction);

    return clone;
  }

  clearInteraction(group : string, interactionId : string) {
    const clone = new Ugla3D(this);

    clone.content.interactions[group] = (clone.content.interactions[group] || []).filter(i => i.id != interactionId);

    return clone;
  }

  mods() {
    return this.content.mods;
  }

  addMods(id : string, mods : Modification[]) {
    const clone = new Ugla3D(this);
    if(!clone.content.mods) {
      clone.content.mods = {};
    }
    clone.content.mods[id] = mods;

    return clone;
  }

  removeMods(id : string) {
    const clone = new Ugla3D(this);

    if(clone.content.mods) {
      delete clone.content.mods[id];
    }

    return clone;
  }

  animations() {
    return this.content.animations;
  }

  addAnimation(id : string, animation : Animation) {
    const clone = new Ugla3D(this);
    if(!clone.content.animations) {
      clone.content.animations = {};
    }
    clone.content.animations[id] = animation;

    return clone;
  }

  removeAnimation(id : string) {
    const clone = new Ugla3D(this);

    if(clone.content.animations) {
      delete clone.content.animations[id];
    }

    return clone;
  }

  lights() {
    return this.content.lights;
  }

  addLight(light : Light) {
    const clone = new Ugla3D(this);
    if(!clone.content.lights) {
      clone.content.lights = [];
    }
    clone.content.lights.push(light);
    return clone;
  }

  updateLight(_indexOrId : number | string, light : Partial<Light>) {
    const clone = new Ugla3D(this);
    const index : number = typeof _indexOrId === 'number' ? _indexOrId : (clone.content.lights || []).findIndex(i => i.id === _indexOrId);

    if(!clone.content.lights) {
      clone.content.lights = [];
    }

    clone.content.lights[index] = {
      ...clone.content.lights[index],
      ...light
    };

    return clone;
  }

  removeLight(indexOrId : number | string) {
    const clone = new Ugla3D(this);
    const index : number = typeof indexOrId === 'number' ? indexOrId : (clone.content.lights || []).findIndex(i => i.id === indexOrId);

    clone.content.lights?.splice(index, 1);
    return clone;
  }
};

