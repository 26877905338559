
import colors from "../../contants/colors";
import IconProps from "./icon-interface";

const FreezeIcon : React.FC<IconProps & {onClick?:()=>void}> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg onClick={p.onClick} className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 88 96" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M86.3 61.8L77.5 64.1L68.2 58.7L78.1 56.1L76.7 50.7L61.4 54.8L49.5999 48L61.4 41.2L76.7 45.3L78.1 39.9L68.2 37.3L77.5 31.9L86.3 34.2L87.8 28.8L80.6 26.9L82.5 19.7L77.1 18.2L74.7 27L65.4 32.4L68.1 22.5L62.5999 21.1L58.5999 36.4L46.7999 43.1V29.6L58 18.4L54.0999 14.4L46.7999 21.6V10.9L53.2 4.5L49.2999 0.5L44 5.8L38.7 0.5L34.7999 4.5L41.2 10.9V21.6L33.9 14.4L30 18.4L41.2 29.6V43.1L29.4 36.4L25.4 21.1L19.9 22.5L22.6 32.4L13.3 27L10.9 18.2L5.49995 19.7L7.39995 26.9L0.199951 28.8L1.69995 34.2L10.5 31.9L19.7999 37.3L9.89995 39.9L11.3 45.3L26.5999 41.2L38.4 48L26.5999 54.8L11.3 50.7L9.89995 56.1L19.7999 58.7L10.5 64.1L1.69995 61.8L0.199951 67.2L7.39995 69.1L5.49995 76.3L10.9 77.8L13.3 69L22.6 63.6L19.9 73.5L25.4 74.9L29.4 59.6L41.2 52.9V66.4L30 77.6L33.9 81.6L41.2 74.4V85.1L34.7999 91.5L38.7 95.5L44 90.2L49.2999 95.5L53.2 91.5L46.7999 85.1V74.4L54.0999 81.6L58 77.6L46.7999 66.4V52.9L58.5999 59.6L62.5999 74.9L68.1 73.5L65.4 63.6L74.7 69L77.1 77.8L82.5 76.3L80.6 69.1L87.8 67.2L86.3 61.8Z" fill={color}/>
    </svg>
    
    
  )
}

export default FreezeIcon;
