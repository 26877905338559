import { useState } from "react";
import colors from "../../contants/colors";

interface DraggableProps {
  horizontal ?: boolean;
  pos : number,
  onChange : (pos : number) => void;
  onDragEnd ?: (pos : number) => void;
  negative ?: boolean;
}

const styles : {[className : string] : React.CSSProperties} = {
  containerV : {
    height : '100%',
    width : '5px',
    cursor : 'col-resize',
    backgroundColor : colors.backgroundLight
  },
  containerH : {
    height : '5px',
    width : '100%',
    cursor : 'row-resize',
    backgroundColor : colors.backgroundLight
  },
  active : {
    backgroundColor : '#999'
  },
  veil : {
    position : 'fixed',
    zIndex : 1000,
    top : 0,
    left : 0,
    width : '100%',
    height : '100%'
  }
}

const Draggable : React.FC<DraggableProps> = (p) => {
  const [initialScreenPos, setInitialScreenPos] = useState<number | false>(false);
  const [initialPos, setInitialPos] = useState<number>(p.pos);

  const handleMouseDown = (e : React.MouseEvent<HTMLDivElement>) => {
    setInitialScreenPos(p.horizontal ? e.screenY : e.screenX);
    setInitialPos(p.pos);
  }

  const handleMouseUp = (e : React.MouseEvent<HTMLDivElement>) => {
    setInitialScreenPos(false)
    if(initialScreenPos) {
      if(p.horizontal) {
        p.onChange(initialPos + (p.negative ? initialScreenPos - e.screenY : e.screenY - initialScreenPos));
        p.onDragEnd?.(initialPos + (p.negative ? initialScreenPos - e.screenY : e.screenY - initialScreenPos));
      }
      else {
        p.onChange(initialPos + (p.negative ? initialScreenPos - e.screenX : e.screenX - initialScreenPos));
        p.onDragEnd?.(initialPos + (p.negative ? initialScreenPos - e.screenX : e.screenX - initialScreenPos));
      }
    }
  }

  const handleMouseMove = (e : React.MouseEvent<HTMLDivElement>) => {
    if(initialScreenPos) {
      if(p.horizontal) {
        p.onChange(initialPos + (p.negative ? initialScreenPos - e.screenY : e.screenY - initialScreenPos));
      }
      else {
        p.onChange(initialPos + (p.negative ? initialScreenPos - e.screenX : e.screenX - initialScreenPos));
      }
    }
  }

  return (
    <div
      style={{...(p.horizontal ? styles.containerH : styles.containerV), ...(initialScreenPos === false ? null : styles.active)}}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      {
        initialScreenPos === false ? null : <div style={styles.veil}/>
      }
    </div>
  )
}

export default Draggable;