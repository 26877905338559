import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import TextInput from "../components/text-input";
import Typography from "../components/typography";
import colors from "../../../contants/colors";
import TextButton from "../components/text-button";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useState } from "react";
import List from "../components/list";
import InteractionsGroupModal from "../modals/interactions-group-modal";

const EditorDefaultInteractionsGroup : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const model = useModelStore(state => state.model);
  const {setDefaultInteractionGroups, clearDefaultInteractionGroups: clearDefaultInteractionGroup} = useModelActions('interactions');
  const [modalVisibility, setModalVisibility] = useState<boolean>(false);
  const groups = model.defaultInteractionsGroup();

  if(!itemSelection || itemSelection.type !== 'defaultInteractionsGroup') {return null;}

  const handleDelete = () => {
    clearDefaultInteractionGroup();
  }

  const handleAdd = (_name : string) => {
    const name = _name.trim();
    if(name && !groups?.find(g => g === name)) {
      setDefaultInteractionGroups([...(groups || []), name]);
    }
  }

  const handleRemove = (index : number) => {
    setDefaultInteractionGroups(groups?.filter((_, i) => i !== index) || []);
  }

  return (
    <Container>
      <Typography variant="mediumTitle">Default interactions group</Typography>
      <List
        items={groups || []}
        onAdd={() => setModalVisibility(true)}
        onDelete={handleRemove}
      />
      <DeleteButtonContainer>
        <TextButton onClick={handleDelete}>Delete all</TextButton>
      </DeleteButtonContainer>
      {modalVisibility && <InteractionsGroupModal onClose={() => setModalVisibility(false)} onSubmit={handleAdd}/>}
    </Container>
  )
}

export default EditorDefaultInteractionsGroup;

const Container = styled.div`
  padding : 1rem;
  color : ${colors.text};
`
const DeleteButtonContainer = styled.div`
  margin-top : 1rem;
`