import { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import colors from "../../../contants/colors";

interface TextSelectProps<T extends string> {
  label ?: string;
  className ?: string;
  value ?: T;
  options ?: {value : T; name : string}[];
  onChange ?: (value : T) => void;
  inline ?: boolean;
};



function TextSelect<T extends string = string>(p : TextSelectProps<T>) : ReactElement {
  return (
    <Container className={p.inline ? 'inline' : ''}>
      {p.label && <Label>{p.label}</Label>}
      <Select onChange={e => p.onChange?.(e.target.value as T)} className={p.className} value={p.value}>
        <option></option>
        {
          (p.options || []).map(option => (
            <option key={option.value} value={option.value}>{option.name}</option>
          ))
        }
      </Select>
    </Container>
  )
}

export default TextSelect;

const Container = styled.div`
  margin-top : 1rem;

  &.inline {
    margin-top : 0;
    margin-right : 0.5rem;
  }
`


const Select = styled.select`
  background-color: ${colors.lightBackground};
  color : ${colors.darkText};
  border : 0.3rem solid transparent;
  border-radius: 0.5rem;
  padding : 0.5rem;
  outline: none;
`

const Label = styled.div`
  color : ${colors.text};
  font-size: 1rem;
  margin-bottom : 0.4rem
`
