import styled from "styled-components";
import { ObjectPath, useSceneState } from "../../../lib";
import md5 from "md5";
import { uniqueId } from "lodash";
import { useCallback, useRef, useState } from "react";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useModelStore } from "../../../store/store";
import colors from "../../../contants/colors";
import { ObjectsTreeValue } from "../../../store/store-types";
import TextSelect from "./text-select";
import TextButton from "./text-button";
import TextInput from "./text-input";
import ObjectPathModal from "../modals/object-path-modal";

interface ObejctPathInputProps {
  value ?: ObjectPath;
  onChange ?: (value : ObjectPath) => void;
  label ?: string;
  mode ?: "2D" | "3D" | "both";
}

const ObjectPathInput : React.FC<ObejctPathInputProps> = (p) => {
  const inputID = useRef<string>(uniqueId());
  const {startSelection, stopSelection, selectObject} = useModelActions('editor');
  const selectionActive = useModelStore(state => state.objectSelection.active);
  const activeInputId = useModelStore(state => state.objectSelection.targetFieldId);
  const interfaceObjectsIDs = useSceneState().filter(obj => obj.asset.type === "2D").map(obj => obj.id);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [mode, setMode] = useState<"2D" | "3D">(!p.mode || p.mode === "both" || p.mode === "3D" ? "3D" : "2D");


  const handleToggle = () => {
    if(selectionActive && inputID.current === activeInputId) {
      stopSelection();
    }
    else {
      startSelection(inputID.current, (selection : ObjectPath[]) => {
        if(selection[0]) {
          p.onChange?.(selection[0])
        }
      }, p.value)
    }
  }

  const handleChangeMode = (mode : "2D" | "3D") => {
    setMode(mode);
    if(mode === '2D') {
      stopSelection();
    }
    p.onChange?.({objectId : '', path : []})
  }

  const handleChange2DSelect = (objectId : string) => {
    p.onChange?.({
      objectId,
      path : []
    })
  }

  const handleChange2DInput = (objectId : string) => {

  }

  const handleModalSubmit = (path : ObjectPath) => {
    selectObject(path);
    p.onChange?.(path);
    setShowModal(false);
  }

  return (
    <Container>
      {p.label && <Label>{p.label}</Label>}
      <Selector>
        {
          p.mode === "both" ?
            <TextSelect<"2D" | "3D">
              options={[{value : '2D', name : '2D'}, {value : '3D', name : '3D'}]}
              onChange={handleChangeMode}
              value={mode}
              inline
            />
           : null
        }
        {
          mode === '3D' ?
            <InlineContainer>
              <TextButton onClick={handleToggle}>{activeInputId === inputID.current ? 'Stop' : 'Select'}</TextButton>&nbsp;
              <div onClick={() => setShowModal(true)}>✏️ </div>
              <InlineContainer>
                {p.value?.objectId}
                {(p.value?.path || []).map((i, index) => <span key={index}>&nbsp;&gt;&nbsp;{i}</span>)}
              </InlineContainer>
            </InlineContainer> :
            <InlineContainer>
              <TextSelect
                inline
                options={[...interfaceObjectsIDs.map(id => ({name : id, value : id})), {name : '[other]', value : '-'}]}
                value={p.value?.objectId && interfaceObjectsIDs.includes(p.value?.objectId) ? p.value?.objectId : '-'}
                onChange={handleChange2DSelect}
              />
              <TextInput inline value={p.value?.objectId} onChange={handleChange2DSelect}/>
              <div onClick={() => setShowModal(true)}>✏️ </div>
            </InlineContainer>
        }
      </Selector>
      {showModal ? <ObjectPathModal objectPath={p.value} onClose={() => setShowModal(false)} onSubmit={handleModalSubmit}/> : null}
    </Container>
  )
}

export default ObjectPathInput;

const Container = styled.div`
margin-top: 1rem;
margin-bottom: 1rem;
color : ${colors.text};
`

const Selector = styled.div`
  display : flex;
  align-content: center;
`;

const Label = styled.div`
  color : ${colors.text};
  font-size: 1rem;
  margin-bottom : 0.4rem;
`;

const InlineContainer = styled.div`
  display: flex;
  align-items : center;
`
