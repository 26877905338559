import { useState } from "react";
import Modal from "../components/modal"
import TextButton from "../components/text-button";
import TextInput from "../components/text-input";
import TextSelect from "../components/text-select";

interface ConnectionModalProps {
  viewerUrl ?: string;
  onClose ?: () => void;
  onSubmit ?: (url : string, appName : string, mode : '2d' | '3d') => void;
}

const ConnectionModal : React.FC<ConnectionModalProps> = (p) => {
  const [url, setUrl] = useState<string | undefined>();
  const [appName, setAppName] = useState<string | undefined>();
  const [mode, setMode] = useState<'2d' | '3d'>('3d');

  const handleConnection = () => {
    if(url && appName) {
      p.onSubmit?.(url, appName, mode)
    }
    p.onClose?.();
  }
  return (
    <Modal
      title={"Connection au viewer unity"}
      onClose={p.onClose}
      controls={
        <TextButton disabled={!url} onClick={handleConnection}>Connect</TextButton>
      }
    >
      <TextInput
        label="URL du viewer Unity"
        value={url}
        onChange={setUrl}
      />
      <TextInput
        label="App name"
        value={appName}
        onChange={setAppName}
      />
      <TextSelect
        label="Mode 2D/3D"
        value={mode}
        onChange={setMode}
        options={[{name : '2D', value : "2d"}, {name : '3D', value : "3d"}]}
      />
    </Modal>
  )
}

export default ConnectionModal

