import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer'

import {ItemSelection, ModelStore, ObjectsTreeValue, SelectionCallback} from './store-types';
import { Ugla3D, Asset, Control, Interaction, ObjectPath, SceneObject, Vec3, Vec4, useViewerStore, stateManager, Light } from '../lib';

const setModel = (model : Ugla3D) => stateManager.setModel(model);

export const useModelStore = create<ModelStore>()(
  immer<ModelStore>(
    (set, get) => ({
      model : new Ugla3D(),
      native : {},
      objectSelection : {
        active : false,
        targetFieldId : '',
        selected : []
      },
      connection : {
        running : false,
        viewerUrl : ''
      },
      actions : {
        connection : {
          start : (viewerUrl : string) => {
            set(state => {
              state.connection.running = true;
              state.connection.viewerUrl = viewerUrl;
            })
          },
          stop : () => {
            set(state => {
              state.connection.running = false;
            })
          }
        },
        editor : {
          selectObject : (sel : ObjectPath) => {
            set(state => {
              state.objectSelection.selected = [sel];
            })
            get().objectSelection.callback?.([sel])
          },

          clearObjectSelection : () => {
            set(state => {
              state.objectSelection.selected = [];
            })
          },

          selectItem : (item : ItemSelection) => {
            set(state => {
              state.itemSelection = item
            })
          },

          startSelection : (targetFieldId : string, callback : SelectionCallback, selected ?: ObjectPath) => {
            if(selected) {
              set(state => {
                state.objectSelection.callback = undefined;
              })
              const state = get();
              const found = useViewerStore.getState().actions.model.getObjectFromPath(selected);
              // const found = state.actions.editor.getObjectFromPath(selected);

              if(found) {
                state.actions.editor.selectObject(selected);
              }
              else {
                state.actions.editor.clearObjectSelection();
              }
            }

            set(state => {
              state.objectSelection.active = true;
              state.objectSelection.targetFieldId = targetFieldId;
              state.objectSelection.callback = callback;
            })
          },

          stopSelection : () => {
            set(state => {
              state.objectSelection.active = false;
              state.objectSelection.targetFieldId = 'targetFieldId';
              state.objectSelection.callback = undefined;
            })

            get().actions.editor.clearObjectSelection();
          },
        },
        model : {
          load : (json : string) => {
            set(state => {
              state.model = setModel(new Ugla3D(json));
            })
          }
        },
        assets : {
          addAsset : (asset : Asset) => {
            set(state => {
              state.model = setModel(state.model.addAsset(asset));
            })
          },
          updateAsset : (index : number, asset : Asset) => {
            set(state => {
              state.model = setModel(state.model.updateAsset(index, asset));
            })
          },
          removeAsset : (index : number) => {
            set(state => {
              state.model = setModel(state.model.removeAsset(index));
            })
          }
        },
        objects : {
          addObject : (obj : SceneObject) => {
            set(state => {
              state.model = setModel(state.model.addObject(obj));
            })
          },
          updateObject : (index : number, obj : SceneObject) => {
            set(state => {
              state.model = setModel(state.model.updateObject(index, obj));
            })
          },
          removeObject : (index : number) => {
            set(state => {
              state.model = setModel(state.model.removeObject(index));
            })
          }
        },
        lights : {
          addLight : (light : Light) => {
            set(state => {
              state.model = setModel(state.model.addLight(light));
            })
          },
          updateLight : (index : number, light : Partial<Light>) => {
            set(state => {
              state.model = setModel(state.model.updateLight(index, light));
            })
          },
          removeLight : (index : number) => {
            set(state => {
              state.model = setModel(state.model.removeLight(index));
            })
          }
        },
        camera : {
          setDefaultPosition : (value : Vec3) => {
            set(state => {
              state.model = setModel(state.model.setCameraDefaultPosition(value));
            })
          },
          clearDefaultPosition : () => {
            set(state => {
              state.model = setModel(state.model.clearCameraDefaultPosition());
            })
          },
          setDefaultRotation : (value : Vec4) => {
            set(state => {
              state.model = setModel(state.model.setCameraDefaultRotation(value));
            })
          },
          clearDefaultRotation : () => {
            set(state => {
              state.model = setModel(state.model.clearCameraDefaultRotation());
            })
          },
          setDefaultFov : (value : number) => {
            set(state => {
              state.model = setModel(state.model.setCameraDefaultFov(value));
            })
          },
          clearDefaultFov : () => {
            set(state => {
              state.model = setModel(state.model.clearCameraDefaultFov());
            })
          }
        },

        controls : {
          setDefaultControls : (controls : Control) => {
            set(state => {
              state.model = setModel(state.model.setDefaultControls(controls));
            })
          },

          clearDefaultControls : () => {
            set(state => {
              state.model = setModel(state.model.clearDefaultControls());
            })
          }
        },

        interactions : {
          setDefaultInteractionGroups : (name : string[]) => {
            set(state => {
              state.model = setModel(state.model.setDefaultInteractionsGroups(name));
            })
          },

          clearDefaultInteractionGroups : () => {
            set(state => {
              state.model = setModel(state.model.clearDefaultInteractionsGroup());
            })
          },

          createInteractionGroup : (group : string) => {
            set(state => {
              state.model = setModel(state.model.createInteractionGroup(group));
            })
          },

          deleteInteractionGroup : (group : string) => {
            set(state => {
              state.model = setModel(state.model.deleteInteractionGroup(group));
            })
          },

          updateInteractionGroup : (previous : string, next : string) => {
            set(state => {
              state.model = setModel(state.model.updateInteractionGroup(previous, next));
            })
          },

          setInteraction : (group : string, interaction : Interaction) => {
            set(state => {
              state.model = setModel(state.model.setInteraction(group, interaction));
            })
          },

          updateInteraction : (group : string, id : string, interaction : Interaction) => {
            set(state => {
              state.model = setModel(state.model.updateInteraction(group, id, interaction));
            })
          },

          clearInteraction : (group : string, interactionId : string) => {
            set(state => {
              state.model = setModel(state.model.clearInteraction(group, interactionId));
            })
          },
        }
      }
    })
  )
)
