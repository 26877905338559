import styled from "styled-components";
import { Material, Modification, Modification2D, Modification3D, ModificationLight, ModificationType } from "../../../lib";
import TextSelect from "./text-select";
import Vec2Input from "./vec2-input";
import Vec3Input from "./vec3-input";
import Vec1Input from "./vec1-input";
import TextButton from "./text-button";
import ObjectPathInput from "./object-path-input";
import TextInput from "./text-input";
import BooleanInput from "./boolean-input";
import AssetInput from "./asset-input";

interface ModInputProps {
  value : Modification;
  onChange : (value : Modification) => void;
  onDelete ?: () => void;
}

const ModInput : React.FC<ModInputProps> = (p) => {

  const handleChangeType = (value : ModificationType) => {
    const modification : Modification = value === '2D' ?
      {
        type : "2D",
        path : {objectId : '', path : []}
      } : value === "light" ?
      {
        type : "light",
        path : {objectId : '', path : []}
      } :
      {
        type : "3D",
        path : {objectId : '', path : []}
      };

    p.onChange(modification)
  }

  const handleUpdate2D = (patch : Partial<Modification2D>) => {
    if(p.value.type === "2D") {
      p.onChange({
        ...p.value,
        ...patch
      })
    }
  }

  const handleUpdate3D = (patch : Partial<Modification3D>) => {
    if(p.value.type === "3D") {
      p.onChange({
        ...p.value,
        ...patch
      })
    }
  }

  const handleUpdateLight = (patch : Partial<ModificationLight>) => {
    if(p.value.type === "light") {
      p.onChange({
        ...p.value,
        ...patch
      })
    }
  }

  const handleUpdateMaterial = (patch : Partial<Modification3D['material']>) => {
    if(p.value.type === "3D") {
      p.onChange({
        ...p.value,
        material : {
          ...p.value.material,
          ...patch
        }
      })
    }
  }


  const handleDeleteField2D = (name : keyof Modification2D) => {
    if(p.value.type === "2D") {
      const newMod = {...p.value};
      delete newMod[name];
      p.onChange(newMod)
    }
  }

  const handleDeleteField3D = (name : keyof Modification3D) => {
    if(p.value.type === "3D") {
      const newMod = {...p.value};
      delete newMod[name];
      p.onChange(newMod)
    }
  }

  const handleDeleteLightField = (name : keyof ModificationLight) => {
    if(p.value.type === "light") {
      const newMod = {...p.value};
      delete newMod[name];
      p.onChange(newMod)
    }
  }

  const handleDeleteMaterialField = (name : keyof Material) => {
    if(p.value.type === "3D") {
      const newMod = {...p.value};
      delete newMod.material?.[name];
      p.onChange(newMod)
    }
  }

  return (
    <Container>
      <TextSelect
        value={p.value.type}
        options={[{value : "2D", name : "2D"}, {value : "3D", name : "3D"}, {value : "light", name : "Light"}]}
        label="Type"
        onChange={value => handleChangeType(value as ModificationType)}
      />
      {
        p.value.type === "2D" ?
        <div>
          <ObjectPathInput mode="2D" label="Object ID" value={p.value.path} onChange={path => handleUpdate2D({path})}/>
          <Vec2Input inline labels={['Position X (px)', 'Position Y (px)']} value={p.value.position} onChange={position => handleUpdate2D({position})} onDelete={() => handleDeleteField2D('position')} />
          <Vec2Input inline labels={['Width (px)', 'Height (px)']} value={p.value.scale} onChange={scale => handleUpdate2D({scale})} onDelete={() => handleDeleteField2D('scale')} />
          <Vec1Input inline label={'Rotation (deg)'} value={p.value.rotation} onChange={rotation => handleUpdate2D({rotation})} onDelete={() => handleDeleteField2D('rotation')} />
          <Vec1Input inline label="zIndex" value={p.value.zIndex} onChange={zIndex => handleUpdate2D({zIndex})} onDelete={() => handleDeleteField2D('zIndex')} />
          <BooleanInput label="Relative" value={p.value.relative} onChange={relative => handleUpdate2D({relative})}/>
        </div> : null
      }
      {
        p.value.type === "3D" ?
        <div>
          <ObjectPathInput mode="3D" label="Object" value={p.value.path} onChange={path => handleUpdate3D({path})}/>
          <Vec3Input inline labels={['Position X', 'Position Y', 'Position Z']} value={p.value.position} onChange={position => handleUpdate3D({position})} onDelete={() => handleDeleteField3D('position')}/>
          <Vec3Input inline labels={['Rotation ψ', 'Rotation θ', 'Rotation φ']} value={p.value.rotation} onChange={rotation => handleUpdate3D({rotation})}  onDelete={() => handleDeleteField3D('rotation')}/>
          <Vec3Input inline labels={['Scale X', 'Scale Y', 'Scale Z']} value={p.value.scale} onChange={scale => handleUpdate3D({scale})} onDelete={() => handleDeleteField3D('scale')}/>
          <TextInput inline label="Material name" value={p.value.materialName} onChange={materialName => handleUpdate3D({materialName})} />
          <TextInput inline label="Base color" value={p.value.material?.baseColor} onChange={baseColor => handleUpdateMaterial({baseColor})} />
          <AssetInput inline label="Diffuse" value={p.value.material?.diffuse} onChange={diffuse => handleUpdateMaterial({diffuse})} onDelete={() => handleDeleteMaterialField('diffuse')}/>
          <AssetInput inline label="Roughness" value={p.value.material?.roughness} onChange={roughness => handleUpdateMaterial({roughness})} onDelete={() => handleDeleteMaterialField('roughness')}/>
          <AssetInput inline label="Normal Map" value={p.value.material?.normalMap} onChange={normalMap => handleUpdateMaterial({normalMap})} onDelete={() => handleDeleteMaterialField('normalMap')}/>
          <Vec2Input inline labels={['Repeat x', 'Repeat y']} value={p.value.material?.tiling} onChange={tiling => handleUpdateMaterial({tiling})} onDelete={() => handleDeleteMaterialField('tiling')} />
          <BooleanInput label="Relative" value={p.value.relative} onChange={relative => handleUpdate3D({relative})}/>
        </div> : null
      }
      {
        p.value.type === "light" ?
        <div>
          <ObjectPathInput mode="3D" label="Object" value={p.value.path} onChange={path => handleUpdateLight({path})}/>
          <Vec3Input inline labels={['Position X', 'Position Y', 'Position Z']} value={p.value.position} onChange={position => handleUpdateLight({position})} onDelete={() => handleDeleteLightField('position')}/>
          <Vec3Input inline labels={['Target X', 'Target Y', 'Target Z']} value={p.value.target} onChange={target => handleUpdateLight({target})} onDelete={() => handleDeleteLightField('target')}/>
          <BooleanInput label="Relative" value={p.value.relative} onChange={relative => handleUpdateLight({relative})}/>
          <TextInput label="Color" value={p.value.color} onChange={color => handleUpdateLight({color})}/>
          <Vec1Input inline label="Intensity" value={p.value.intensity} onChange={intensity => handleUpdateLight({intensity})} onDelete={() => handleDeleteLightField('intensity')}/>
          <Vec1Input inline label="Distance" value={p.value.distance} onChange={distance => handleUpdateLight({distance})} onDelete={() => handleDeleteLightField('distance')}/>
          <Vec1Input inline label="Decay" value={p.value.decay} onChange={decay => handleUpdateLight({decay})} onDelete={() => handleDeleteLightField('decay')}/>
          <Vec1Input inline label="Angle" value={p.value.angle} onChange={angle => handleUpdateLight({angle})} onDelete={() => handleDeleteLightField('angle')}/>
          <Vec1Input inline label="Penumbra" value={p.value.penumbra} onChange={penumbra => handleUpdateLight({penumbra})} onDelete={() => handleDeleteLightField('penumbra')}/>
          <BooleanInput label="Cast Shadow" value={p.value.castShadow} onChange={castShadow => handleUpdateLight({castShadow})}/>
        </div> : null
      }
      <ButtonContainer>
        <TextButton onClick={p.onDelete}>Remove</TextButton>
      </ButtonContainer>
    </Container>
  )
}

export default ModInput;

const Container = styled.div`

`

const ButtonContainer = styled.div`
  margin-top: 1rem;
  text-align: right;
`