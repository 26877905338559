import styled from "styled-components";
import TextInput from "./text-input";
import { useState } from "react";
import TextButton from "./text-button";
import RemoveCrossIcon from "../../../assets/icons/remove-cross";

interface Vec1InputProps {
  value ?: number;
  onChange ?: (value : number) => void;
  onDelete ?: () => void;
  label ?: string;
  inline ?: boolean;
}

const Vec1Input : React.FC<Vec1InputProps> = (p) => {
  const [x, setX] = useState<string>(p.value ? `${p.value}` : '');
  const [xError, setXError] = useState<boolean>(false);

  const cleanupValue = (x : string) => {
    if(!isNaN(parseFloat(x))) {
      const val : number = parseFloat(x);
      p.onChange?.(val);
    }

    setXError(isNaN(parseFloat(x)))
  }

  const handleChangeX = (value : string) => {
    setX(value);
    cleanupValue(value);
  }

  const handleDelete = () => {
    if(p.onDelete) {
      setX('');
      p.onDelete();
    }
  }


  return (
    <Container className={p.inline ? "inline" : ''}>
      <TextInput
        label={p.label || "X"}
        value={x}
        onChange={handleChangeX}
        isError={xError}
      />
      {
        p.onDelete ?
          <DeleteButtonContainer className={p.inline ? "inline" : ''}>
            <TextButton icon={<RemoveCrossIcon color="black"/>} onClick={handleDelete}>{p.inline ? undefined : 'Delete'}</TextButton>
          </DeleteButtonContainer> :
          null
      }
    </Container>
  )
}

export default Vec1Input;

const Container = styled.div`
  &.inline {
    display: flex;

    & > div {
      margin-right : 1rem;
    }

    & > div:last-child {
      margin-right : 0rem;
    }

    & > div:first-child {
      flex : 1;
    }
  }
`

const DeleteButtonContainer = styled.div`
  margin-top : 1rem;

  &.inline {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`