import { useState } from "react";
import { ObjectPath } from "../../../lib";
import Modal from "../components/modal"
import TextSelect from "../components/text-select";
import TextButton from "../components/text-button";
import TextInput from "../components/text-input";
import { useModelStore } from "../../../store/store";
import BooleanInput from "../components/boolean-input";
import List from "../components/list";
import Typography from "../components/typography";
import colors from "../../../contants/colors";
import styled from "styled-components";

interface ObjectPathModalProps {
  objectPath ?: ObjectPath;
  onClose ?: () => void;
  onSubmit ?: (objectPath : ObjectPath) => void;
}

const ObjectPathModal : React.FC<ObjectPathModalProps> = (p) => {
  const [objectId, setObjectId] = useState<string>(p.objectPath?.objectId || '');
  const [path, setPath] = useState<string[]>(p.objectPath?.path.map(p => `${p}`) || []);
  const [regex, setRegEx] = useState<boolean>(!!p.objectPath?.regex);
  const [newPath, setNewPath] = useState<string>('');

  const handleValidate = () => {
    p.onClose?.();
    p.onSubmit?.({
      objectId,
      path : path.map(p => p.trim().match(/^[0-9]+$/) ? parseInt(p) : p),
      regex
    })
  }

  const handleDeleteItem = (index : number) => {
    const newPath = [...path];
    newPath.splice(index, 1);
    setPath(newPath);
  }

  const handleAdd = () => {
    if(newPath.trim()) {
      setNewPath('');
      setPath(path => [...path, newPath]);
    }
  }

  return (
    <Modal
      title={"Object path"}
      onClose={p.onClose}
      controls={
        <TextButton onClick={handleValidate}>Validate</TextButton>
      }
    >
      <TextInput
        label="Id de l'objet"
        value={objectId}
        onChange={setObjectId}
        />
      <BooleanInput
        label="Regex"
        value={regex}
        onChange={setRegEx}
      />
      <TextInput
        label="New item"
        value={newPath}
        onChange={setNewPath}
      />
      <StyledTypography variant={"label"}>Path</StyledTypography>
      <List
        items={path}
        onDelete={handleDeleteItem}
        onAdd={handleAdd}
      />
    </Modal>
  )
}

export default ObjectPathModal

const StyledTypography = styled(Typography)`
  display: inline-block;
  margin-top : 1rem;
  color : ${colors.text};
`