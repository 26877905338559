import styled from "styled-components";
import colors from "../../../contants/colors";
import useAuthProvider from "../../../providers/authprovider";

const LogoutButton = () => {
  const {authProvider} = useAuthProvider();
  return <TextToolButton onClick={() => authProvider.logout()}>Logout</TextToolButton>
}

export default LogoutButton;

const TextToolButton = styled.button`
  background-color: ${colors.background};
  color: ${colors.text};
  border: none;
  cursor: pointer;
`