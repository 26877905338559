import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import TagsInput from "../components/tags-input";
import { useModelActions } from "../../../store/hooks/use-actions";
import { Modification, Tag } from "../../../lib";
import colors from "../../../contants/colors";
import ModsInput from "../components/mods-input";
import Tabs from "../components/tabs";

const ObjectEditor : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const model = useModelStore(state => state.model);
  const {updateObject} = useModelActions('objects');

  if(!itemSelection || itemSelection.type !== 'object') {return null;}

  const object = model.objects()[itemSelection.index];

  if(!object) {return null;}

  const updateTags = (tags : Tag[]) => {
    updateObject(itemSelection.index, {
      ...object,
      tags
    })
  }

  const deleteTags = () => {
    const newObject = {...object};
    delete newObject['tags'];

    updateObject(itemSelection.index, newObject);
  }

  const updateMods = (mods : Modification[]) => {
    updateObject(itemSelection.index, {
      ...object,
      mods
    })
  }

  const deleteMods = () => {
    const newObject = {...object};
    delete newObject['mods'];

    updateObject(itemSelection.index, newObject);
  }


  return (
    <Container>
      <Tabs names={['Mods', 'Tags']}>
        {[
          <ModsInput key={itemSelection.index} value={object.mods} onChange={updateMods} onDelete={deleteMods}/>,
          <TagsInput key={itemSelection.index} value={object.tags} onChange={updateTags} onDelete={deleteTags}/>
        ]}
      </Tabs>
    </Container>
  )
}

export default ObjectEditor;

const Container = styled.div`
  color : ${colors.text};
`