import styled from "styled-components";
import colors from "../../../contants/colors";
import { useModelStore } from "../../../store/store";
import Tree, {Node } from '../../controls/components/tree'
import { ObjectsTreeValue } from "../../../store/store-types";
import { useState } from "react";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useViewerStore } from "../../../lib";

const ObjectsTree : React.FC<{}> = (p) => {
  const tree = useViewerStore(state => state.objectsTree);
  const {selectObject: select} = useModelActions('editor');
  const selection = useModelStore(state => state.objectSelection.selected[0]);


  const handleSelect = (value : ObjectsTreeValue) => {
    select(value.path);
  }

  const isSelected = (value : ObjectsTreeValue) => {
    return !!selection &&
      selection.objectId === value.path.objectId &&
      selection.path.length === value.path.path.length &&
      selection.path.every((v,index) => value.path.path[index] === v);
  }

  if(!tree) {return null;}

  return (
    <Container>
      <Tree<ObjectsTreeValue> tree={tree} onSelect={handleSelect} isSelected={isSelected}/>
    </Container>
  )
}

export default ObjectsTree;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
  overflow: auto;
`

