import styled from "styled-components";
import { Vec3 } from "../../../lib/model/ugla-filetype";
import TextInput from "./text-input";
import { useState } from "react";
import TextButton from "./text-button";
import RemoveCrossIcon from "../../../assets/icons/remove-cross";

interface Vec3InputProps {
  value ?: Vec3;
  onChange ?: (value : Vec3) => void;
  onDelete ?: () => void;
  labels ?: [string, string, string];
  inline ?: boolean;
}

const Vec3Input : React.FC<Vec3InputProps> = (p) => {
  const [x, setX] = useState<string>(p.value ? `${p.value[0]}` : '');
  const [y, setY] = useState<string>(p.value ? `${p.value[1]}` : '');
  const [z, setZ] = useState<string>(p.value ? `${p.value[2]}` : '');
  const [xError, setXError] = useState<boolean>(false);
  const [yError, setYError] = useState<boolean>(false);
  const [zError, setZError] = useState<boolean>(false);

  const cleanupValue = (x : string, y : string, z : string) => {
    if(!isNaN(parseFloat(x)) && !isNaN(parseFloat(y)) && !isNaN(parseFloat(z))) {
      const val : Vec3 = [parseFloat(x), parseFloat(y), parseFloat(z)];
      p.onChange?.(val);
    }

    setXError(isNaN(parseFloat(x)))
    setYError(isNaN(parseFloat(y)))
    setZError(isNaN(parseFloat(z)))
  }

  const handleChangeX = (value : string) => {
    setX(value);
    cleanupValue(value, y, z);
  }

  const handleChangeY = (value : string) => {
    setY(value);
    cleanupValue(x, value, z);
  }

  const handleChangeZ = (value : string) => {
    setZ(value);
    cleanupValue(x, y, value);
  }

  const handleDelete = () => {
    if(p.onDelete) {
      setX('');
      setY('');
      setZ('');
      p.onDelete();
    }
  }

  return (
    <Container  className={p.inline ? "inline" : ''}>
      <TextInput
        label={p.labels?.[0] || "X"}
        value={x}
        onChange={handleChangeX}
        isError={xError}
      />
      <TextInput
        label={p.labels?.[1] || "Y"}
        value={y}
        onChange={handleChangeY}
        isError={yError}
      />
      <TextInput
        label={p.labels?.[2] || "Z"}
        value={z}
        onChange={handleChangeZ}
        isError={zError}
      />
      {
        p.onDelete ?
          <DeleteButtonContainer className={p.inline ? "inline" : ''}>
            <TextButton icon={<RemoveCrossIcon color="black"/>} onClick={handleDelete}>{p.inline ? undefined : 'Delete'}</TextButton>
          </DeleteButtonContainer> :
          null
      }
    </Container>
  )
}

export default Vec3Input;

const Container = styled.div`
  &.inline {
    display: flex;

    & > div {
      margin-right : 1rem;
    }

    & > div:last-child {
      margin-right : 0rem;
    }
  }
`

const DeleteButtonContainer = styled.div`
  margin-top : 1rem;

  &.inline {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`