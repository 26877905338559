import { useRef } from "react";
import * as THREE from 'three'

const CLICK_TOLLERANCE = 3;

interface SceneObjectProps {
  id : string;
  scene : THREE.Group<THREE.Object3DEventMap>;
  onClick ?: (e : any) => void;
}

const SceneObject : React.FC<SceneObjectProps> = (p) => {
  const canvasRef = useRef();

  // Hande click manually as default primitive onClick fires also when mouse is moved between
  // mouse down and mouse up.
  const isDown = useRef<boolean>(false);
  const downPosition = useRef<[number, number]>([0, 0]);

  const handlePointerDown = (e : any) => {
    isDown.current = true;
    downPosition.current = [e.clientX, e.clientY];
  }

  const handlePointerUp = (e : any) => {
    if(isDown?.current) {
      isDown.current = false;
      p.onClick?.(e);
    }
  }

  const handlePointerMove = (e : any) => {
    if(
      Math.abs(e.clientX - downPosition?.current[0]) > CLICK_TOLLERANCE ||
      Math.abs(e.clientY - downPosition?.current[1]) > CLICK_TOLLERANCE
    ) {
      isDown.current = false;
    }
  }

  return <primitive
    ref={canvasRef}
    object={p.scene}
    onPointerMove={handlePointerMove}
    onPointerUp={handlePointerUp}
    onPointerDown={handlePointerDown}
  />
}

export default SceneObject;
