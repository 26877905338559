import styled from "styled-components";
import colors from "../../../contants/colors";
import { ReactNode, useState } from "react";

interface TabsProps {
  names : string[];
  children : ReactNode[];
}

const Tabs : React.FC<TabsProps> = (p) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const child = p.children[selectedTab];

  return (
    <Container>
      <Header>
        {
          p.names.map((name, index) => (
            <Tab key={name} className={index === selectedTab ? 'selected' : ''} onClick={() => setSelectedTab(index)}>{name}</Tab>
          ))
        }
      </Header>
      {
        p.children.map((child, index) => (
          <TabPanel key={p.names[index]} style={index === selectedTab ? {display : 'block'} : {display : 'none'}}>
            {child}
          </TabPanel>
        ))
      }
    </Container>
  )
}

export default Tabs;

const Container = styled.div`
  flex : 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  margin-bottom : 0.2rem;
  border-bottom : 1px solid ${colors.backgroundLight};
`

const Tab = styled.div`
  color : ${colors.text};
  font-size: 1.4rem;
  font-weight: bolder;
  padding : 1rem;
  cursor : pointer;

  &.selected {
    background-color: ${colors.backgroundLight};
  }
`

const TabPanel = styled.div`
  flex : 1;
  padding : 1rem;
  overflow: auto;
  position: relative;
`
