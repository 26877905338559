import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import ArchiveIcon from "../../../assets/icons/archive";
import FreezeIcon from "../../../assets/icons/freeze";
import { useUgla3dState, useUgla3dStateManager } from "../../../lib";
import { useModelActions } from "../../../store/hooks/use-actions";
import Tooltip from "../components/tooltip";

const FreezeButton : React.FC<{}> = (p) => {
  const stateManager = useUgla3dStateManager()
  const {load} = useModelActions('model');

  const handleFreeze = () => {
    load(JSON.stringify(stateManager.toModel().getContent()))
  }

  return (
    <>
      <Tooltip content="Freeze state into model">
        <Button>
          <FreezeIcon onClick={handleFreeze}/>
        </Button>
      </Tooltip>
    </>
  )
}

export default FreezeButton;

const Button = styled.div`
  width : 3rem;
  height : 3rem;
  padding : 0.5rem;
`
