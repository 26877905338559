import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import colors from "../../../contants/colors";
import Typography from "../components/typography";
import TextSelect from "../components/text-select";
import { useModelActions } from "../../../store/hooks/use-actions";
import { LightType, Vec3 } from "../../../lib";
import TextInput from "../components/text-input";
import Vec1Input from "../components/vec1-input";
import Vec3Input from "../components/vec3-input";
import LightInput from "../components/light-input";

const LightEditor : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const model = useModelStore(state => state.model);
  const {updateLight} = useModelActions('lights');

  if(!itemSelection || itemSelection.type !== 'light') {return null;}

  const light = (model.lights() || [])[itemSelection.index];

  if(!light) {return null;}

  const handleChange = (type : string) => {
    updateLight(itemSelection.index, {type : type as LightType});
  }

  console.log(light)

  return (
    <Container>
      <Typography variant="mediumTitle">Default lights</Typography>
      <LightInput light={light} onChange={light => updateLight(itemSelection.index, light)}/>
    </Container>
  )
}

export default LightEditor;

const Container = styled.div`
  padding : 1rem;
  color : ${colors.text};
`