import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MainPage from "./pages/main";
import { useModelStore } from "./store/store";
import LoginPage from "./pages/login";
import authProvider from "./providers/authprovider";
import { useModelActions } from "./store/hooks/use-actions";
import { useEffect } from "react";
import styled from "styled-components";
import colors from "./contants/colors";

import packageJson from '../package.json';
import useAuthProvider from "./providers/authprovider";


const loggedRouter = createBrowserRouter([
  {path: "/", element: <MainPage />}
]);

const unloggedRouter = createBrowserRouter([
  {path: "/", element: <LoginPage />}
])

export default function App() {
  const {authProvider, logged} = useAuthProvider();

  useEffect(() => {
    authProvider.init();
  }, [])

  return (
    <>
      <RouterProvider router={logged ? loggedRouter : unloggedRouter} />
      <Version>v{packageJson.version}</Version>
    </>
  )
}


const Version = styled.div`
  position: fixed;
  bottom : 0.5rem;
  left : 0.5rem;
  font-size: 1rem;
  color : ${colors.text};
  background-color : ${colors.background};
  z-index: 1000;
`