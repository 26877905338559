import NameModal from "./name-modal";

interface LightModalProps {
  id ?: string;
  onClose ?: () => void;
  onSubmit ?: (id : string) => void;
} 

const LightModal : React.FC<LightModalProps> = (p) => {
  return (
    <NameModal title="Light" label="Light name" name={p.id} onClose={p.onClose} onSubmit={p.onSubmit}/>
  )
}

export default LightModal;