import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import Tooltip from "../components/tooltip";
import { exportToGlb} from "../../../lib";
import GLBIcon from "../../../assets/icons/glb";

const ExportToGlb : React.FC<{}> = (p) => {
  const handleDownload = async () => {
    var fileContent = await exportToGlb();
    var bb = new Blob([fileContent], { type: 'model/gltf-binary' });
    var a = document.createElement('a');
    a.download = 'export.glb';
    a.href = window.URL.createObjectURL(bb);
    a.click();
  }

  return (
    <>
      <Tooltip content="Export to GLB">
        <Button><GLBIcon onClick={handleDownload}/></Button>
      </Tooltip>
    </>
  )
}

export default ExportToGlb;

const Button = styled.div`
  width : 3rem;
  height : 3rem;
  padding : 0.5rem;
`
