import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import Typography from "../components/typography";
import colors from "../../../contants/colors";
import Vec3Input from "../components/vec3-input";
import { useModelActions } from "../../../store/hooks/use-actions";

const EditorDefaultRotation : React.FC<{}> = (p) => {
  const itemSelection = useModelStore(state => state.itemSelection);
  const model = useModelStore(state => state.model);
  const defaultRotation = model.camera()?.defaultRotation;
  const {setDefaultRotation, clearDefaultRotation} = useModelActions('camera');

  if(!itemSelection || itemSelection.type !== 'camera' || itemSelection.item !== 'rotation') {return null;}

  return (
    <Container>
      <Typography variant="mediumTitle">Default rotation</Typography>
      <Vec3Input
        value={defaultRotation && [defaultRotation[0], defaultRotation[1], defaultRotation[2]]}
        onChange={v => setDefaultRotation([...v, 1])}
        onDelete={clearDefaultRotation}
        labels={["ψ", "θ", "φ"]}
      />
    </Container>
  )
}

export default EditorDefaultRotation;

const Container = styled.div`
  padding : 1rem;
  color : ${colors.text}
`
