import { ReactNode } from "react";
import styled from "styled-components";
import colors from "../../../contants/colors";

interface TextButtonProps {
  icon ?: ReactNode
  className ?: string;
  onClick ?: () => void;
  children ?: ReactNode;
  disabled ?: boolean;
  inline ?: boolean;
}

const TextButton : React.FC<TextButtonProps> = (p) => {  
  return (
    <Button disabled={!!p.disabled} onClick={p.onClick} className={(p.className || ' ') + (p.inline ? 'inline' : '') + ' ' + (p.disabled ? 'disabled' : '')}>
      {
        p.icon ? <IconContainer>{p.icon}</IconContainer> : null
      }
      {
        p.children ? <Label className={p.disabled ? 'disabled' : ''}>{p.children}</Label> : null
      }
    </Button>
  )
}

export default TextButton;

const Button = styled.button`
  background-color: ${colors.lightBackground};
  color : ${colors.darkText};
  border : none;
  border-radius: 0.5rem;
  padding : 0.5rem;
  outline: none;
  white-space: nowrap;

  &.inline {
    margin-right : 0.5rem;
  }

  &:active {
    color : ${colors.text};
    background-color: ${colors.backgroundMiddle};
  }
`

const IconContainer = styled.div`
  height : 1.2rem;
  width : 1.2rem;
  display: inline-block;

  &:nth-last-child(2) {
    margin-right : 0.5rem;
  }
`

const Label = styled.div`
  display: inline-block;

  &.disabled {
    color : ${colors.backgroundLight}
  }
`