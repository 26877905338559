import { useEffect, useState } from 'react';
import Keycloak from 'keycloak-js';
import configProvider from "./configprovider";



export class AuthProvider {
  private keycloak : Keycloak;
  private onAuthSuccessCallbacks : (() => void)[] = [];
  private onAuthLogoutCallbacks : (() => void)[] = [];

  constructor() {
    this.keycloak = new Keycloak({
      url: configProvider('AUTH_SERVER_URL'),
      realm: configProvider('AUTH_SERVER_REALM'),
      clientId: configProvider('AUTH_SERVER_CLIENT_ID')
    });

    this.keycloak.onAuthSuccess = () => {
      this.onAuthSuccessCallbacks.forEach(callback => callback());
    }
    this.keycloak.onAuthLogout = () => {
      this.onAuthLogoutCallbacks.forEach(callback => callback());
    }
  }

  onAuthSuccess = (callback : () => void) => {
    this.onAuthSuccessCallbacks.push(callback);
  }
  onAuthLogout = (callback : () => void) => {
    this.onAuthLogoutCallbacks.push(callback);
  }
  offAuthSuccess = (callback : () => void) => {
    this.onAuthSuccessCallbacks = this.onAuthSuccessCallbacks.filter(cb => cb !== callback);
  }
  offAuthLogout = (callback : () => void) => {
    this.onAuthLogoutCallbacks = this.onAuthLogoutCallbacks.filter(cb => cb !== callback);
  }

  async init () {
    if(this.keycloak.didInitialize) {
      return await this.isAuthenticated();
    }

    try {
      await this.keycloak.init(
        {
          onLoad: 'login-required',
          checkLoginIframe: false,
          token: localStorage.getItem('kc_token') || '',
          refreshToken: localStorage.getItem('kc_refresh_token') || ''
        }
      );

      return await this.isAuthenticated();
    }
    catch(error) {
      console.error('Failed to initialize adapter:', error);
      return false;
    }
  }

  async isAuthenticated () {
    return new Promise(resolve => {
      let timeoutId : NodeJS.Timeout;

      const resolveCallback = () => {
        this.offAuthSuccess(resolveCallback);
        clearTimeout(timeoutId);
        const authenticated = !!this.keycloak.authenticated;
        if(authenticated) {
          localStorage.setItem('kc_token', this.keycloak.token || '');
          localStorage.setItem('kc_refresh_token', this.keycloak.refreshToken || '');
        }
        else {
          localStorage.removeItem('kc_token');
          localStorage.removeItem('kc_refresh_token');
        }
        resolve(authenticated);
      }

      timeoutId = setTimeout(resolveCallback, 1000);
      this.onAuthSuccess(resolveCallback);

      if(this.keycloak.authenticated && this.keycloak.token) {
        resolveCallback();
      }
    });
  }

  async login () {
    await this.keycloak.login();
  }

  async logout () {
    localStorage.removeItem('kc_token');
    localStorage.removeItem('kc_refresh_token');

    await this.keycloak.logout();
  }

  async getToken () {
    await this.keycloak.updateToken(30);
    return this.keycloak.token;
  }
}

const authProvider = new AuthProvider();

const useAuthProvider = () => {
  const [logged, setLogged] = useState<boolean>(false);

  useEffect(() => {
    const onAuthSuccessCallback = () => setLogged(true);
    const onAuthLogoutCallback = () => setLogged(false);

    authProvider.onAuthSuccess(onAuthSuccessCallback);
    authProvider.onAuthLogout(onAuthLogoutCallback);

    return () => {
      authProvider.offAuthSuccess(onAuthSuccessCallback);
      authProvider.offAuthLogout(onAuthLogoutCallback);
    }
  }, [])

  return {
    authProvider,
    logged
  }
}

export default useAuthProvider;
