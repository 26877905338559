import styled from "styled-components";
import colors from "../../../contants/colors";
import Acordeon from "../components/acordeon";
import AssetsPanel from "../subpanels/assets";
import ScenePanel from "../subpanels/scene";
import CameraPanel from "../subpanels/camera";
import ObjectsTree from "./objects-tree";
import ControlsPanel from "../subpanels/controls";
import InteractionsPanel from "../subpanels/interactions";
import Tabs from "../components/tabs";
import LightsPanel from "../subpanels/lights";

const Navigator : React.FC<{}> = (p) => {
  return (
    <Container>
      <Tabs names={['File', '3D']}>
        {[
          <Acordeon key="acordeon" panels={[
            {label : 'assets', node : <AssetsPanel />},
            {label : 'scene', node : <ScenePanel />},
            {label : 'lights', node : <LightsPanel />},
            {label : 'camera', node : <CameraPanel />},
            {label : 'controls', node : <ControlsPanel />},
            {label : 'interactions', node : <InteractionsPanel />}
          ]}/>,
          <ObjectsTree key="ObjectsTree"/>
        ]}
      </Tabs>
    </Container>
  )
}

export default Navigator;

const Container = styled.div`
  position: absolute;
  top : 0;
  left : 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.background};
  display: flex;
  flex-direction: column;
`;